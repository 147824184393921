<template>
  <note-component :field="note.field"
                  v-for="(note, i) in notes"
                  :key="'note_'+i"
                  :label="note.label" />
</template>

<script>
import NoteComponent from "@/views/case/components/NoteComponent.vue";
export default {
  components: {NoteComponent},
  data() {
    return {
      notes: [
        {
          field: 'note_subjective',
          label: 'Subjective',
        },
        {
          field: 'note_objective',
          label: 'Objective',
        },
        {
          field: 'note_assessment',
          label: 'Assessment',
        },
        {
          field: 'note_plan',
          label: 'Plan',
        },
        {
          field: 'note_diagnosis',
          label: 'Diagnosis',
        }
      ]
    }
  }
}
</script>