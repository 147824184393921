<template>
  <div class="row mb-3">
    <div class="col-12">
      <div class="d-flex align-items-center rounded  p-2 bg-light">
        <div class="me-15">
          <img src="/images/avatar-girl.png" class="avatar avatar-lg rounded10">
        </div>
        <div class="d-flex flex-column flex-grow-1 fw-500">
          <span class="text-fade">Customer name</span>
          <a href="#" class="text-dark hover-primary mb-1 fs-16">
            {{ form.patient_type === '1' ? patient.full_name : (form.patient.first_name + ' ' + form.patient.last_name) }}
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-12">
      <div class="box">
        <div class="box-header small-box-header">
          <h4 class="box-title">Information</h4>
        </div>
        <div class="box-body p-3">
          <div class="row">
            <div class="col-3">
              <h4>{{ $t('patients.profile') }}</h4>
              <table class="patient-details-table table table-borderless">
                <tr>
                  <th>{{ $t('patients.hn_number') }}</th>
                  <td>{{ patient.hn }}</td>
                </tr>
                <tr>
                  <th class="pt-3">{{ $t('patients.prefix') }}</th>
                  <td class="pt-3">{{ patient.namePrefix ? patient.namePrefix.prefix : '' }}</td>
                </tr>
                <tr>
                  <th>{{ $t('patients.first_name') }}</th>
                  <td>{{ patient.first_name }}</td>
                </tr>
                <tr>
                  <th>{{ $t('patients.last_name') }}</th>
                  <td>{{ patient.last_name }}</td>
                </tr>
                <tr>
                  <th>{{ $t('patients.nick_name') }}</th>
                  <td>{{ patient.nick_name }}</td>
                </tr>
                <tr>
                  <th>{{ $t('patients.gen') }}</th>
                  <td>{{ patient.gender }}</td>
                </tr>
                <tr>
                  <th class="pt-3">{{ $t('patients.birthday') }}</th>
                  <td class="pt-3">{{ patient.dob_format }}</td>
                </tr>
                <tr>
                  <th>{{ $t('patients.age') }}</th>
                  <td>{{ patient.age }}</td>
                </tr>
                <tr>
                  <th class="pt-3">{{ $t('patients.occupation') }}</th>
                  <td class="pt-3">{{ patient.occupation }}</td>
                </tr>
                <tr>
                  <th>({{ $t('patients.other') }})</th>
                  <td>{{ patient.occupation_other }}</td>
                </tr>
                <tr>
                  <th class="pt-3">{{ $t('patients.nationality') }}</th>
                  <td class="pt-3">th{{ patient.nationality }}</td>
                </tr>
                <tr>
                  <th>{{ $t('patients.national_id') }}</th>
                  <td>{{ patient.id_card_number }}</td>
                </tr>
                <tr>
                  <th>{{ $t('patients.passport_no') }}.</th>
                  <td>{{ patient.passport_number }}</td>
                </tr>
                <tr>
                  <th class="pt-3">{{ $t('patients.blood_type') }}</th>
                  <td class="pt-3">{{ patient.bloodGroup ? patient.bloodGroup.name : '' }}</td>
                </tr>
              </table>
            </div>
            <div class="col-3">
              <h4>{{ $t('patients.contact') }}</h4>
              <table class="patient-details-table table table-borderless">
                <tr>
                  <th>{{ $t('patients.country') }}</th>
                  <td>{{ patient.country ? patient.country.name : '' }}</td>
                </tr>
                <tr>
                  <th>{{ $t('patients.phone') }}</th>
                  <td>{{ patient.mobile }}</td>
                </tr>
                <tr>
                  <th>{{ $t('patients.backup_phone') }}</th>
                  <td>{{ patient.mobile_alternative }}</td>
                </tr>
                <tr>
                  <th>{{ $t('patients.email') }}</th>
                  <td>{{ patient.email }}</td>
                </tr>
              </table>
              <h4>{{ $t('patients.address') }}</h4>
              <table class="patient-details-table table table-borderless">
                <tr>
                  <th>{{ $t('patients.country') }}</th>
                  <td>{{ patient.address && patient.address.country ? patient.address.country.name : '' }}</td>
                </tr>
                <tr>
                  <th>{{ $t('patients.street') }}</th>
                  <td>{{ patient.address ? patient.address.street : '' }}</td>
                </tr>
                <tr>
                  <th>{{ $t('patients.street_2') }}</th>
                  <td>{{ patient.address ? patient.address.street2 : '' }}</td>
                </tr>
                <tr>
                  <th>{{ $t('patients.district') }}</th>
                  <td>{{ patient.address ? patient.address.sub_district : '' }}</td>
                </tr>
                <tr>
                  <th>{{ $t('patients.province') }}</th>
                  <td>{{ patient.address ? patient.address.province : '' }}</td>
                </tr>
                <tr>
                  <th>{{ $t('patients.zip') }}</th>
                  <td>{{ patient.address ? patient.address.zip_code : '' }}</td>
                </tr>
                <tr>
                  <th>{{ $t('patients.phone_home') }}</th>
                  <td>{{ patient.address ? patient.address.phone : '' }}</td>
                </tr>
              </table>
            </div>
            <div class="col-3">
              <h4>{{ $t('patients.emergency_contact') }}</h4>
              <table class="patient-details-ta table table-borderless"
                     v-for="contact in patient.emergencyContacts"
                     :key="'contact_'+contact.id">
                <tr>
                  <th>{{ $t('patients.name') }}</th>
                  <td>{{ contact.name }}</td>
                </tr>
                <tr>
                  <th>{{ $t('patients.relation') }}</th>
                  <td>{{ contact.relationship ? contact.relationship.name : '' }}</td>
                </tr>
                <tr>
                  <th>({{ $t('patients.other') }})</th>
                  <td>{{ contact.relationship_other }}</td>
                </tr>
                <tr>
                  <th>{{ $t('patients.country') }}</th>
                  <td>{{ contact.country ? contact.country.name : '' }}</td>
                </tr>
                <tr>
                  <th>{{ $t('patients.phone') }}</th>
                  <td>{{ contact.mobile }}</td>
                </tr>
                <tr>
                  <th>{{ $t('patients.email') }}</th>
                  <td>{{ contact.email }}</td>
                </tr>
                <tr>
                  <th>{{ $t('patients.address') }}</th>
                  <td></td>
                </tr>
              </table>
            </div>
            <div class="col-3">
              <template v-if="patient.congenital_disease">
                <h4>{{ $t('patients.congenital_disease') }}</h4>
                <p>{{ patient.congenital_disease }}</p>
              </template>

              <template v-if="patient.drug_allergy">
                <h4>{{ $t('patients.medical_allergies') }}</h4>
                <p>{{ patient.drug_allergy }}</p>
              </template>

              <template v-if="patient.note">
                <h4>{{ $t('patients.note') }}</h4>
                <p>{{ patient.note }}</p>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-4">
      <div class="box" v-if="$route.name === 'case_requests_task_mapping'">
        <div class="box-header small-box-header">
          <h4 class="box-title">Visit Details</h4>
        </div>
        <div class="box-body p-3" v-if="newCaseRequest">
          <table class="patient-details-table table table-borderless table-sm">
            <tr>
              <td>
                <span style="color: rgb(81, 86, 190);">◆</span> Visit ID
              </td>
              <td>: {{ newCaseRequest.visitTask.visit.visit_no }}</td>
            </tr>
            <tr>
              <td>
                <span style="color: rgb(81, 86, 190);">◆</span> Task ID
              </td>
              <td>: {{ newCaseRequest.visitTask.task_id }}</td>
            </tr>
            <tr>
              <td>
                <span style="color: rgb(81, 86, 190);">◆</span> Time
              </td>
              <td>: {{ caseRequest.start_time_format + ' - ' + caseRequest.end_time_format }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="box" v-else>
        <div class="box-header small-box-header">
          <h4 class="box-title">Visit Details</h4>
        </div>
        <div class="box-body p-3" v-if="newCaseRequest">
          <table class="patient-details-table table table-borderless table-sm">
            <tr>
              <td>
                <span style="color: rgb(81, 86, 190);">◆</span> Visit ID
              </td>
              <td>: {{ newCaseRequest.visitCase.visit.visit_no }}</td>
            </tr>
            <tr>
              <td>
                <span style="color: rgb(81, 86, 190);">◆</span> Case ID
              </td>
              <td>: {{ newCaseRequest.visitCase.case_no }}</td>
            </tr>
            <tr>
              <td>
                <span style="color: rgb(81, 86, 190);">◆</span> Time
              </td>
              <td>: {{ caseRequest.start_time_format + ' - ' + caseRequest.end_time_format }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="box" v-if="$route.name === 'case_requests_task_mapping'">
        <div class="box-header small-box-header">
          <h4 class="box-title">Notes</h4>
        </div>
        <div class="box-body p-1">
          <p v-html="caseRequest.koalendar_description" v-if="caseRequest"></p>
        </div>
      </div>
      <div class="box" v-else>
        <div class="box-header small-box-header">
          <h4 class="box-title">Services</h4>
        </div>
        <div class="box-body p-1">
          <ul class="list-group list-group-flush">
            <li class="list-group-item"
                v-for="service in form.services"
                :key="'service_'+service.id">
              <i class="fas fa-bookmark"></i> {{ service.name_th }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="box">
        <div class="box-header small-box-header">
          <h4 class="box-title">Doctor</h4>
        </div>
        <div class="box-body p-3">
          <div class="d-flex align-items-center">
            <div class="me-15">
              <img :src="form.doctor.image ? form.doctor.image : '/images/avatar-girl.png'"
                   onerror="this.src='/images/avatar-girl.png'"
                   class="avatar avatar-lg rounded10">
            </div>
            <div class="d-flex flex-column flex-grow-1 fw-500">
              <a href="#" class="text-dark hover-primary mb-1 fs-16">{{ form.doctor.full_name }}</a>
              <span class="text-fade">{{ form.doctor.department.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['formData', 'errors', 'currentPatient', 'caseRequest'],
  data() {
    return {
      form: {},
      newCaseRequest: null,
    }
  },
  computed: {
    patient() {
      return this.currentPatient;
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    errors: {
      handler(newValue) {
        this.allErrors = newValue;
      },
      deep: true
    },
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    },
  },
  mounted() {
    this.loadCaseRequest()
  },
  methods: {
    loadCaseRequest() {
      this.newCaseRequest = null;

      this.axios.get('/case-requests/' + this.$route.params.id)
        .then(res => {
          this.newCaseRequest = res.data.data;
        })
    },
  }
}
</script>