<template>
  <div class="modal fade bs-example-modal-lg"
       id="create-document-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">
            Select Document
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="box">
            <div class="box-body p-3">
              <div v-for="document in documents"
                   :key="'document_'+document.id">

                <span v-if="document.title" class="d-block text-muted mb-3">{{ document.title }}</span>

                <div :class="{'ms-3': document.title}">
                  <div class="form-group"
                       v-for="doc in document.docs"
                       :key="'doc_'+doc.id">
                    <div class="checkbox checkbox-success">
                      <input :id="doc.title"
                             v-model="forms.files"
                             type="checkbox"
                             :value="doc.file">
                      <label :for="doc.title">{{ doc.title }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal">ยกเลิก</button>

          <button class="btn btn-success"
                  :disabled="loading"
                  @click="submit">ดาวน์โหลด</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      forms: {
        files: []
      },
      documents: [
        {
          id: 1,
          title: '',
          docs: [
            {
              id: 1,
              title: 'OPD Record',
              file: 'Opd Reccord.pdf'
            },
            {
              id: 2,
              title: 'Nurse Note สำหรับ IV',
              file: 'nurse note สำหรับ IV.pdf'
            },
            {
              id: 3,
              title: 'Nurse Note สำหรับติดตามอาการ',
              file: 'NURSE NOTE สำหรับนัดติดตามอาการ.pdf'
            },
          ]
        },
        {
          id: 2,
          title: 'ชุด set OR',
          docs: [
            {
              id: 1,
              title: 'ใบ Order สำหรับ OR',
              file: 'Order OR.pdf'
            },
            {
              id: 2,
              title: 'ใบ Order ใบเปล่า',
              file: 'Order ใบเปล่า.pdf'
            },
            {
              id: 3,
              title: 'ใบรับส่งข้อมูล OPD-OR',
              file: 'ใบรับส่งข้อมูล OPD-OR.pdf'
            },
            {
              id: 4,
              title: 'ใบบันทึกตำแหน่งผ่าตัด',
              file: 'ใบบันทึกตำแหน่งผ่าตัด.pdf'
            },
            {
              id: 5,
              title: 'ใบ Operative Note 1',
              file: 'Operative note 1.pdf'
            },
            {
              id: 6,
              title: 'ใบ Operative Note 2',
              file: 'Operative note 2.pdf'
            },
            {
              id: 7,
              title: 'ใบ Recovery Room Record',
              file: 'recovery room record MED.pdf'
            },
            {
              id: 8,
              title: 'ใบยินยอมผ่าตัด',
              file: 'Consent form ใบยินยอมผ่าตัด.pdf'
            },
            {
              id: 9,
              title: 'ใบยินยอมรับยาระงับความรู้สึก',
              file: 'ใบยินยอมรับยาระงับความรู้สึกตัว.pdf'
            },
          ]
        },
        {
          id: 3,
          title: '',
          docs: [
            {
              id: 1,
              title: 'ใบ Patient Transfer Summary',
              file: 'patient transfer summary.pdf'
            }
          ]
        }
      ]
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('create-document-modal'));
  },
  methods: {
    show() {
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    submit() {
      this.loading = true;

      this.axios.post('/visit-cases/create-document', this.forms)
        .then((res) => {

          const linkSource = 'data:application/pdf;base64,'+ res.data.pdf_base64;
          const downloadLink = document.createElement("a");
          downloadLink.href = linkSource;
          downloadLink.download = 'document.pdf';
          downloadLink.click();

          this.hide();

          /*let file = res.data.data;

          this.download(file.url, file.filename);
          this.hide();*/
        })
        .finally(() => {
          this.loading = false;
        })
    }
  }
}
</script>