<template>
  <div class="box">
    <div class="box-header small-box-header">
      <div class="row">
        <div class="col-6">
          <h4 class="box-title">Services</h4>
        </div>
        <div class="col-6 text-end">
          <button class="btn btn-primary-light btn-sm"
                  @click="$refs.serviceSelectModal.show()">+Add Service</button>
        </div>
      </div>
    </div>
    <div class="box-body">
      <table class="table">
        <thead>
        <tr>
          <th>Service No.</th>
          <th>ชื่อบริการ</th>
        </tr>
        </thead>

        <tbody>
        <tr v-for="(service, i) in form.services" :key="'service_'+service.id">
          <td>{{ i+  1}}</td>
          <td>{{ service.name_th }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <service-select-modal @selected="addService"
                        ref="serviceSelectModal" />
</template>

<script>
import ServiceSelectModal from "@/components/modals/ServiceSelectModal.vue";
export default {
  components: {ServiceSelectModal},
  props: ['doctors', 'formData', 'errors'],
  data() {
    return {
      form: {}
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    errors: {
      handler(newValue) {
        this.allErrors = newValue;
      },
      deep: true
    },
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    },
  },
  methods: {
    addService(service) {
      if (!this.form.services.map(s => s.id).includes(service.id))
        this.form.services.push(service);
    }
  }
}
</script>