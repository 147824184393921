<template>
  <div class="row">
    <div class="col-md-12">
      <div class="box">
        <div class="box-header">
          <h4 class="mb-0">บริการที่เลือก ทั้งหมด <span class="text-bold text-primary">{{ form.cases[0].services.length }}</span> รายการ</h4>
        </div>
        <div class="box-body">
          <div class="myadmin-dd dd" id="nestable2">
            <ol class="dd-list">
              <li class="dd-item"
                  v-for="item in items"
                  :key="'item_'+item.id">
                <div class="dd-handle normal-cursor">
                  {{ item.name_th }}
                  <span class="text-bold text-primary">({{ item.services.filter(s => form.cases[0].services.map(s => s.id).includes(s.id)).length }})</span>
                </div>
                <ol class="dd-list">
                  <li class="dd-item"
                      v-for="service in item.services"
                      :key="'service+'+service.id">
                    <div class="dd-handle normal-cursor">
                      <div class="checkbox">
                        <input type="checkbox"
                               :value="service"
                               v-model="form.cases[0].services"
                               :id="'service_checkbox_'+service.id">
                        <label :for="'service_checkbox_'+service.id">{{ service.name_th }}</label>
                      </div>
                    </div>
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['formData', 'errors'],
  data() {
    return {
      items: [],
      form: {},
    }
  },
  created() {
    this.loadServices();
  },
  computed: {
    totalServices() {
      let total = 0;

      this.items.forEach(item => {
        total += item.services.filter(service => service.selected).length;
      })

      return total;
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    items: {
      handler() {
        this.form.service_ids = [];

        this.items.forEach(item => {
          item.services.filter(service => service.selected)
            .forEach(s => {
              this.form.service_ids.push(s.id)
            });
        })
      },
      deep: true
    },
    errors: {
      handler(newValue) {
        this.allErrors = newValue;
      },
      deep: true
    },
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    },
  },
  mounted() {
    window.$('#nestable2').nestable({
      handleClass: '123'
    })
  },
  methods: {
    loadServices() {
      this.items = [];

      this.axios.get('/service-categories')
        .then(res => {
          this.items = res.data.data;
        })
    }
  }
}
</script>