<template>
  <div class="content-header pb-0" v-if="caseRequest">
    <div class="row">
      <div class="col-6">
        <h3 class="page-title">Request Detail : {{ caseRequest.request_id }}</h3>
      </div>
    </div>
  </div>

  <hr>

  <div class="row" v-if="caseRequest">
    <div class="col-3">
      <div class="box min-h-200">
        <div class="box-header small-box-header">
          <h4 class="box-title">Information</h4>
        </div>
        <div class="box-body p-2">
          <table class="table table-borderless table-small">
            <tr>
              <td>Status</td>
              <td>: Request</td>
            </tr>
            <tr>
              <td>Case ID</td>
              <td>: -</td>
            </tr>
            <tr>
              <td>Request Date</td>
              <td>: {{ caseRequest.created_at }}</td>
            </tr>
            <tr>
              <td>Contact Email</td>
              <td>: {{ caseRequest.koalendar_customer_email }}</td>
            </tr>
            <tr>
              <td>Doctor</td>
              <td>: {{ caseRequest.koalendar_doctor_name }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="col-9">
      <div class="box min-h-200">
        <div class="box-header small-box-header">
          <h4 class="box-title">Request Detail</h4>
        </div>
        <div class="box-body p-3">
          <p v-html="caseRequest.koalendar_description"></p>
        </div>
      </div>
    </div>
  </div>

  <hr>

  <div class="row" v-if="caseRequest && caseRequest.status === 1">
    <div class="col-12 text-center">
      <router-link v-if="$route.query.task"
                   :to="{name: 'case_requests_task_mapping'}"
                   class="btn btn-success me-2">Accept</router-link>
      <router-link v-else
                   :to="{name: 'case_requests_mapping'}"
                   class="btn btn-success me-2">Accept</router-link>
      <button class="btn btn-danger me-2" @click="rejectCase">Reject</button>
      <router-link :to="{name: 'case_requests'}"
                   class="btn btn-secondary">Close</router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      caseRequest: null
    }
  },
  created() {
    this.loadCaseRequest()
  },
  methods: {
    loadCaseRequest() {
      this.caseRequest = null;

      this.axios.get('/case-requests/' + this.$route.params.id)
        .then(res => {
          this.caseRequest = res.data.data;
        })
    },
    rejectCase() {
      this.$swal({
        title: 'Reject case?',
        text: "Are you sure want reject?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Reject',
        cancelButtonText: 'Cancel',
        confirmButtonColor: 'red',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.post('/case-requests/reject/' + this.caseRequest.id)
            .then(() => {
              this.$router.push({name: 'case_requests'});
            })
        }
      });
    }
  }
}
</script>