<template>
  <div class="form-group-sm row">
    <label class="col-sm-1 col-form-label">คำค้นหา</label>
    <div class="col-sm-11">
      <div class="input-group mb-3">
        <input type="text"
               v-model="queryParams.search"
               class="form-control form-control-sm">
        <span class="input-group-text"><i class="ti-search"></i></span>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-1 col-form-label">หมวดหมู่</label>
    <div class="col-sm-4">
      <select class="form-select form-select-sm"
              v-model="queryParams.product_category_id">
        <option value="">หมวด: ทุกหมวด</option>
        <option :value="category"
                v-for="(category, i) in productCategories"
                :key="'category_'+i">{{ category }}</option>
      </select>
    </div>
<!--    <label class="col-sm-1 col-form-label">หมู่</label>
    <div class="col-sm-4">
      <select class="form-select form-select-sm"
              v-model="queryParams.product_group_category_id">
        <option value="">หมู่: ทุกหมู่</option>
        <option :value="group.id"
                v-for="group in productGroupCategories"
                :key="'group_'+group.id">{{ group.name_th }}</option>
      </select>
    </div>-->

<!--    <div class="col-sm-2">
      <div class="form-group">
        <div class="checkbox checkbox-success">
          <input id="checkbox2" type="checkbox">
          <label for="checkbox2"> แสดงเฉพาะรายการยังไม่สั่ง </label>
        </div>
      </div>
    </div>-->
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="box">
        <div class="box-body">
          <table class="table table-small">
            <thead>
            <tr>
              <th class="text-center">เลือก</th>
              <th>รหัสสินค้า</th>
              <th>ชื่อสินค้า (ขาย)</th>
              <th>หมวด</th>
              <th>หน่วยนับ</th>
              <th class="text-end">คงเหลือ</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="product in products"
                      :key="'item_'+product.product_id">
              <tr :class="{'table-active': product.disabled}"
                  v-if="!queryParams.search || product.product_name.toLowerCase().includes(queryParams.search.toLowerCase())">
                <td class="text-center">
                  <div class="checkbox checkbox-success">
                    <input :id="'checkbox_'+product.product_id"
                           :value="product.product_id"
                           v-model="form.product_ids"
                           type="checkbox">
                    <label :for="'checkbox_'+product.product_id"></label>
                  </div>
                </td>
                <td>{{ product.product_id }}</td>
                <td>{{ product.product_name }}</td>
                <td>{{ product.category }}</td>
                <td>{{ product.unit }}</td>
                <td class="text-end">{{ product.balance }}</td>
              </tr>
            </template>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: ['formData'],
  data() {
    return {
      form: {},
      queryParams: {
        search: '',
        product_category_id: '',
        product_group_category_id: '',
      },
    }
  },
  computed: {
    productCategories() {
      return this.products
        .map(p => p.category)
        .filter((value, index, array) => array.indexOf(value) === index)
        .filter(c => c);
    },
    ...mapGetters({
      products: 'prescriptionModule/getProducts',
      // productCategories: 'prescriptionModule/getProductCategories',
      // productGroupCategories: 'prescriptionModule/getProductGroupCategories'
    })
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    queryParams: {
      handler(newValue) {
        this.$store.commit('prescriptionModule/setQueryParams', newValue);
        this.$store.dispatch('prescriptionModule/loadProducts');
      },
      deep: true
    }
  },
  methods: {
    changeCategory() {
      this.queryParams.product_group_category_id = '';

      this.$store.dispatch('prescriptionModule/loadProductGroupCategories')
    },
  }
}
</script>