<template>
  <div class="modal fade bs-example-modal-lg"
       id="select-service-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            Select Service
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div :class="['form-group form-group-sm row mb-0']">
            <label class="col-sm-2 col-form-label">พนักงาน</label>
            <div :class="['col-sm-10 form-group mb-2']">
              <select class="form-select form-select-sm"
                      v-model="service_id">
                <option value="">Select Service</option>
                <option :value="service.id"
                        v-for="service in services"
                        :key="'service_'+service.id">{{ service.name_th }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal">ยกเลิกการสร้าง</button>

          <button class="btn btn-success"
                  :disabled="loading || !service_id"
                  @click="submit">บันทึก</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      services: [],
      service_id: '',
    }
  },
  created() {
    this.loadServices();
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('select-service-modal'));
  },
  methods: {
    resetForm() {
      this.service_id = '';
    },
    show() {
      this.resetForm();

      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    loadServices() {
      this.services = [];

      this.axios.get('/services')
        .then(res => {
          this.services = res.data.data;
        })
    },
    submit() {
      this.hide();

      if (this.service_id) {
        this.$emit('selected', this.services.find(s => s.id === this.service_id))
      }

    },
  }
}
</script>