<template>
  <div class="modal fade bs-example-modal-lg"
       id="upload-document-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">
            Upload Files
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form v-if="!forms.files.length" id="my-form" class="dropzone" />

          <div v-else class="table-responsive">
            <table class="table table-hover table-small">
              <thead>
              <tr>
                <th>File name</th>
                <th>Type</th>
                <th class="text-end">Action</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(file, i) in forms.files" :key="'file_'+file.filename">
                <td>{{ file.filename }}</td>
                <td>
                  <select class="form-select form-select-sm"
                          v-model="file.type">
                    <option value="Consent Form">Consent Form</option>
                    <option value="PDPA">PDPA</option>
                    <option value="OPD Record">OPD Record</option>
                  </select>
                </td>
                <td class="text-end">
                  <a href="#" @click.prevent="forms.files.splice(i, 1)">
                    <i class="fas fa-trash"></i> Delete
                  </a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer text-center" v-if="forms.files.length">
          <button type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal">ยกเลิก</button>

          <button class="btn btn-success"
                  :disabled="loading"
                  @click="submit">บันทึก</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import Dropzone from "dropzone";
import {mapGetters} from "vuex";
export default {
  data() {
    return {
      modal: null,
      forms: {
        files: []
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/getUser'
    })
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('upload-document-modal'));
  },
  methods: {
    show() {
      this.forms.files = [];
      this.modal.show();
      this.initDropzone();
    },
    hide() {
      this.modal.hide();
    },
    initDropzone() {
      let myDropzone = new Dropzone("#my-form", {
        url: process.env.VUE_APP_API_BASE_URL + '/visit-cases/upload-document',
        headers: {
          'Authorization': 'Bearer ' + this.user.token
        }
      });
      myDropzone.on("addedfile", file => {
        this.forms.files.push({
          filename: file.name,
          file: file,
          type: 'Consent Form'
        });
      });
    },
    submit() {
      this.loading = true;

      let formData = new FormData();

      this.forms.files.forEach(f => {
        formData.append('file[]', f.file);
        formData.append('type[]', f.type);
      });


      this.axios.post('/visit-cases/upload-document/' + this.$route.params.id, formData)
        .then(() => {
          this.$emit('added');
          this.hide();
        })
        .finally(() => {
          this.loading = false;
        })
    }
  }
}
</script>