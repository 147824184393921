<template>
  <div class="box">
    <div class="box-header small-box-header">
      <h4 class="box-title mb-0">เลือก Visit</h4>
    </div>
    <div class="box-body p-3">
      <div class="row">
        <div class="col-4">
          <div class="box">
            <div class="box-header small-box-header">
              <h4 class="box-title mb-0">Request Details</h4>
            </div>
            <div class="box-body" v-if="doctor && slot">
              <div>
                <div class="d-flex align-items-center mb-10">
                  <div class="me-15">
                    <img src="http://127.0.0.1:8000/storage/patients_avatars/3e53f06a-1e78-452e-a6e0-7e05bc172655.jpeg" class="avatar avatar-lg rounded10 bg-primary-light" alt="">
                  </div>
                  <div class="d-flex flex-column flex-grow-1 fw-500">
                    <span class="text-dark fs-16">{{ doctor.full_name }}</span>
                    <p class="hover-primary text-fade mb-1 fs-14">{{ slot.date_format }}</p>
                  </div>
                </div>
                <h6>Services</h6>
                <template v-for="service in services.filter(s => form.cases[0].services.map(s => s.id).includes(s.id))"
                          :key="'service_'+service.id">
                  <span style="color:#5156BE;">◆</span> {{ service.name_th }} <br>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="col-8">
          <template v-if="visits.length" >
            <visit-component v-for="visit in visits"
                             @selectVisit="selectVisit"
                             :visit="visit"
                             :key="'visit_'+visit.id"
                             :formData="form" />
          </template>

          <no-visit v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import NoVisit from "./NoVisit.vue";
import VisitComponent from "./VisitComponent.vue";

export default {
  props: ['formData', 'errors'],
  components: {NoVisit, VisitComponent},
  data() {
    return {
      form: {},
      visits: []
    }
  },
  computed: {
    ...mapGetters({
      doctor: 'iframeModule/getDoctor',
      slot: 'iframeModule/getSlot',
      services: 'iframeModule/getServices'
    })
  },
  beforeMount() {
    this.form = this.formData;
    this.loadVisits();
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    },
  },
  methods: {
    selectVisit(visit) {
      this.form.visit_id = visit.id;
      this.$emit('submit')
    },
    loadVisits() {
      this.visits = [];

      if (this.form.selected_patient_id) {
        this.axios.get('/visits?patient_id=' + this.form.selected_patient_id + '&date=' + this.form.cases[0].date)
          .then(res => {
            this.visits = res.data.data;
          })
      }
    }
  }
}
</script>