<template>
  <div class="row">
    <div class="col-md-6">
      <h4>Documents</h4>
    </div>

    <div class="col-md-6 text-end">
      <button class="btn btn-sm btn-info me-2"
              @click="$refs.createDocumentModal.show()">
        <i class="fas fa-plus"></i> Create
      </button>

      <button class="btn btn-sm btn-info"
              @click="$refs.uploadDocumentModal.show()">
        <i class="fas fa-upload"></i> Upload
      </button>
    </div>
  </div>

  <hr>

  <table class="table table-hover">
    <thead>
    <tr>
      <th>ชื่อไฟล์</th>
      <th>Type</th>
      <th class="text-end">Action</th>
    </tr>
    </thead>

    <tbody>
    <tr v-for="document in documents"
        :key="'document_'+document.id">
      <td>{{ document.original_filename }}</td>
      <td>{{ document.type }}</td>
      <td class="text-end">
        <a class="text-muted"
           target="_blank"
           :href="document.file">
          <i class="fas fa-file"></i> View
        </a>
        |
        <a class="text-muted"
           @click.prevent="download(document.file, document.original_filename)"
           href="#">
          <i class="fas fa-download"></i> Download
        </a>
      </td>
    </tr>
    </tbody>
  </table>

  <input @change="readDocumentURL($event.target)"
         type="file"
         ref="documentInput" style="display: none">

  <create-document-modal ref="createDocumentModal" />
  <upload-document-modal @added="loadDocuments" ref="uploadDocumentModal" />
</template>

<script>
import {mapGetters} from "vuex";
import CreateDocumentModal from "@/views/case/modals/CreateDocumentModal.vue";
import UploadDocumentModal from "@/views/case/modals/UploadDocumentModal.vue";

export default {
  components: {CreateDocumentModal, UploadDocumentModal},
  data() {
    return {
      documents: []
    }
  },
  computed: {
    ...mapGetters({
      caseObj: 'caseModule/getCaseObj'
    })
  },
  created() {
    this.loadDocuments()
  },
  methods: {
    loadDocuments() {
      this.documents = [];

      this.axios.get('/visits/documents?visit_case_id=' + this.$route.params.id)
        .then(res => {
          this.documents = res.data.data;
        })
    },
    readDocumentURL(input) {
      if (input.files && input.files[0]) {
        let formData = new FormData();

        formData.append('file', input.files[0]);
        formData.append('visit_id', this.caseObj.visit_id);
        formData.append('visit_case_id', this.$route.params.id);
        formData.append('patient_id', this.caseObj.patient.id);
        formData.append('original_filename',  input.files[0].name);

        this.axios.post('/visits/document', formData)
          .then(() => {
            this.loadDocuments()
          });
      }
    }
  }
}
</script>