<template>
  <div class="box mb-2" v-for="visit in visits"
       :key="'visit_'+visit.id">
    <div class="box-body p-2">
      <div class="box bg-light">
        <div class="box-body p-2">
          <table class="patient-details-table table table-borderless table-sm mb-3">
            <tr>
              <td>
                <span style="color: rgb(81, 86, 190);">◆</span> Visit No.
              </td>
              <td>: {{ visit.visit_no }}</td>
            </tr>
            <tr>
              <td>
                <span style="color: rgb(81, 86, 190);">◆</span> No of Cases
              </td>
              <td>: {{ visit.cases.length }}</td>
            </tr>
          </table>

          <button class="btn btn-sm btn-primary" disabled v-if="visit.id == visitId">Selected</button>
          <button class="btn btn-sm btn-primary-light"
                  v-else
                  @click="selectVisit(visit)">+Add Case to Visit</button>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 text-center">
      <button class="btn btn-sm btn-primary-light"
              v-if="!creatingNewVisit"
              @click="createVisit">Create New Visit</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['visits', 'creatingNewVisit', 'visitId'],
  methods: {
    createVisit() {
      this.$emit('createVisit');
    },
    selectVisit(visit) {
      this.$emit('selectVisit', visit);
    }
  }
}
</script>