<template>
  <div class="modal fade bs-example-modal-lg"
       id="iframe-slot-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel" v-if="doctor">
            Doctor Slot : {{ doctor.full_name }}
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body p-3 h-500">
          <div id="past-calendar"></div>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      modal: null,
      slots: [],
      events: []
    }
  },
  computed: {
    ...mapGetters({
      doctor: 'iframeModule/getDoctor'
    })
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('iframe-slot-modal'));
  },
  methods: {
    show() {
      this.modal.show();
      this.loadSlots();
    },
    hide() {
      this.modal.hide();
    },
    loadSlots() {
      this.slots = [];

      this.axios.get('/doctor-slots?status=0&doctor_id=' + this.doctor.id)
        .then(res => {
          this.slots = res.data.data;

          this.events = res.data.data.map(e => {
            let event = {
              id: e.id,
              start: e.start_time,
              end: e.end_time,
            }

            event.title = e.slot_id;
            event.className = 'bg-secondary';

            return event;
          })

          setTimeout(() => {
            this.initCalender();
          }, 500)
        })
    },
    initCalender() {
      let self = this;

      let calendarEl = document.getElementById('past-calendar');
      let calendar = new window.FullCalendar.Calendar(calendarEl, {
        initialView: 'dayGridMonth',
        height: '100%',
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,dayGridWeek,timeGridDay,listWeek'
        },
        themeSystem: 'bootstrap5',
        events: self.events,
        eventClick: function(info) {
          let slot = self.slots.find(s => s.id == info.event.id);

          self.$store.commit('iframeModule/setSlot', slot)
          self.$emit('selectSlot', slot)
          self.hide();
        }
      });
      calendar.render();
    }
  }
}
</script>