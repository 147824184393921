<template>
  <div class="modal fade bs-example-modal-lg"
       id="add-new-follow-up"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">
            Create New Task
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body p-3 pt-0">
          <div class="row bg-secondary-light p-2 mb-3" v-if="caseObj">
            <div class="col-4">
              <table>
                <tbody>
                <tr>
                  <th>Type</th>
                  <td>: Follow-Up</td>
                </tr>
                <tr>
                  <th>Case ID</th>
                  <td>: {{ caseObj.case_no }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="col-4">
              <table>
                <tbody>
                <tr>
                  <th>Doctor</th>
                  <td>: {{ caseObj.doctor.full_name }}</td>
                </tr>
                <tr>
                  <th>Patient</th>
                  <td>: {{ caseObj.patient.full_name }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="col-4">
              <table>
                <tbody>
                <tr>
                  <th>HN</th>
                  <td>: {{ caseObj.patient.hn }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="box" v-for="(task, i) in tasks" :key="'task_'+i">
            <div class="box-header small-box-header">
              <h4 class="box-title">Task {{ i+1 }}</h4>
            </div>
            <div class="box-body p-3">
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('tasks.'+i+'.date')}]">
                <label class="col-sm-2 col-form-label">Date</label>
                <div class="col-sm-10">
                  <input type="date"
                         v-model="task.date"
                         class="form-control form-control-sm">
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('tasks.'+i+'.time')}]">
                <label class="col-sm-2 col-form-label">Time</label>
                <div class="col-sm-10">
                  <input type="time"
                         v-model="task.time"
                         class="form-control form-control-sm">
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('tasks.'+i+'.title')}]">
                <label class="col-sm-2 col-form-label">Title</label>
                <div class="col-sm-10">
                  <input type="text"
                         v-model="task.title"
                         class="form-control form-control-sm">
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('tasks.'+i+'.description')}]">
                <label class="col-sm-2 col-form-label">Description</label>
                <div class="col-sm-10">
                  <textarea rows="4"
                            v-model="task.description"
                            class="form-control form-control-sm" />
                </div>
              </div>
            </div>
          </div>

          <button class="btn btn-sm btn-primary-light"
                  @click="addTask">+ Add more</button>
        </div>
        <div class="modal-footer text-center">
          <button type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal">Cancel</button>

          <button class="btn btn-success"
                  :disabled="loading"
                  @click="submit">Create</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      modal: null,
      tasks: [
        {
          date: '',
          type: 'Follow-up',
          time: '',
          title: '',
          description: ''
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      caseObj: 'caseModule/getCaseObj'
    })
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('add-new-follow-up'));
  },
  methods: {
    show() {
      this.tasks = [{
        date: '',
        time: '',
        title: '',
        description: '',
        type: 'Follow-up',
      }];

      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    addTask() {
      this.tasks.push(
        {
          date: '',
          time: '',
          title: '',
          description: '',
          type: 'Follow-up',
        }
      )
    },
    submit() {
      this.allErrors = null;
      this.loading = true;

      let form = {
        visit_id: this.caseObj.visit_id,
        visit_case_id: this.caseObj.id,
        patient_id: this.caseObj.patient.id,
        tasks: this.tasks
      }

      this.axios.post('/visit-tasks', form)
        .then(() => {
          this.modal.hide();
          this.$emit('added');
        })
        .catch((err) => {
          this.allErrors = err.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  }
}
</script>