<template>
  <div class="content-header pb-0">
    <div class="row" v-if="caseRequest">
      <div class="col-6">
        <h3 class="page-title">Request Detail : {{ caseRequest.request_id }}</h3>
      </div>
    </div>
  </div>

  <hr>

  <summary-component
    :formData="form"
    :caseRequest="caseRequest"
    :currentPatient="currentPatient"
    v-if="currentStep === totalStep+1" />

  <template v-else>
    <div class="row mb-4">
      <div class="col-12">
        <button :class="'btn btn-sm me-2 ' + (currentStep === 1 ? 'btn-primary' : 'btn-secondary')">1. Map Customer</button>
        <button :class="'btn btn-sm me-2 ' + (currentStep === 2 ? 'btn-primary' : 'btn-secondary')">2. Map Doctor</button>
        <button :class="'btn btn-sm me-2 ' + (currentStep === 3 ? 'btn-primary' : 'btn-secondary')">3. {{ $route.name === 'case_requests_task_mapping' ? 'Task Details' : 'Map Services' }}</button>
        <button :class="'btn btn-sm ' + (currentStep === 4 ? 'btn-primary' : 'btn-secondary')">4. Map Visit</button>
      </div>
    </div>

    <div class="row" v-if="caseRequest">
      <div class="col-3">
        <div class="box">
          <div class="box-header small-box-header">
            <h4 class="box-title">Information</h4>
          </div>
          <div class="box-body p-2">
            <table class="table table-borderless table-small">
              <tr>
                <td>Status</td>
                <td>: Request</td>
              </tr>
              <tr>
                <td>Case ID</td>
                <td>: -</td>
              </tr>
              <tr>
                <td>Request Date</td>
                <td>: {{ caseRequest.created_at }}</td>
              </tr>
              <tr>
                <td>Contact Email</td>
                <td>: {{ caseRequest.koalendar_customer_email }}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="box">
          <div class="box-header small-box-header">
            <h4 class="box-title">Request Detail</h4>
          </div>
          <div class="box-body p-3">
            <p v-html="caseRequest.koalendar_description"></p>
          </div>
        </div>
      </div>
      <div class="col-9 max-h-500 overflow-scroll">
        <component :formData="form"
                   :doctors="doctors"
                   :caseRequest="caseRequest"
                   @nextStep="nextStep"
                   :currentPatient="currentPatient"
                   :errors="allErrors"
                   :is="currentComponent" />
      </div>
    </div>
  </template>

  <hr>

  <div class="row">
    <div class="col-md-12 text-center">
      <router-link class="btn btn-secondary me-2"
                   v-if="currentStep <= totalStep"
                   :to="{name: 'case_requests'}">Cancel</router-link>
      <button type="button"
              @click="prevStep"
              v-if="currentStep <= totalStep"
              :disabled="currentStep === 1"
              :class="['btn me-2', {'btn-info-light': currentStep === 1}, {'btn-primary': currentStep !== 1}]">
        Back
      </button>
      <button type="button"
              @click="nextStep"
              v-if="currentStep < totalStep"
              :disabled="!canGoNext"
              :class="['btn', {'btn-info-light': currentStep === 4}, {'btn-primary': currentStep !== 4}]">
        Next
      </button>
      <button type="button"
              @click="submit"
              v-if="currentStep === totalStep"
              :disabled="!canGoNext || loading"
              class="btn btn-success">
        Finish
      </button>
      <router-link :to="{name: 'case_notes', params: {id: caseRequest.visitCase.id}}"
              v-if="currentStep === totalStep + 1 && caseRequest && caseRequest.visitCase"
              class="btn btn-success">
        View Details
      </router-link>

      <router-link :to="{name: 'tasks_view', params: {id: caseRequest.visitTask.id}}"
                   v-if="currentStep === totalStep + 1 && caseRequest && caseRequest.visitTask"
                   class="btn btn-success">
        View Details
      </router-link>
    </div>
  </div>
</template>

<script>
import StepOne from "@/views/appointment/newVisitModal/steps/1/StepOne.vue";
import useVuelidate from "@vuelidate/core";
import {required, minLength} from "@vuelidate/validators";
import StepTwo from './steps/2/StepTwo.vue';
import StepThree from './steps/3/StepThree.vue';
import StepThreeTask from './steps/3/StepThreeTask.vue';
import StepFour from './steps/4/StepFour.vue';
import SummaryComponent from './Summary.vue'
export default {
  components: {StepOne, StepTwo, StepThree, StepFour, SummaryComponent, StepThreeTask},
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    if (this.currentStep === 1) {
      if (this.form.patient_type === '1') {
        return {
          form: {
            selected_patient_id: {required},
          },
        }
      } else {
        return {
          form: {
            patient: {
              name_prefix_id: {required},
              first_name: {required},
              last_name: {required},
              mobile: {required},
              blood_group_id: {required},
              congenital_disease: {required},
              drug_allergy: {required},
              dob: {required},
            }
          }
        }
      }
    } else if (this.currentStep === 2) {
      return {
        form: {
          doctor_id: {required},
        },
      }
    } else if (this.currentStep === 3) {
      if (this.$route.name === 'case_requests_task_mapping') {
        return {
          form: {
            task: {
              title: { required },
              description: { required },
            },
          },
        }
      } else {
        return {
          form: {
            services: { required, minLength: minLength(1), },
          },
        }
      }
    }
  },
  data() {
    return {
      currentStep: 1,
      totalStep: 4,
      caseRequest: null,
      doctors: [],
      form: {
        creatingNewVisit: false,
        currentPatient: null,
        doctor_id: '',
        doctor: null,
        services: [],
        visit: null,
        visit_id: '',
        date: window.moment().format('YYYY-MM-DD'),
        patient_type: null,
        selected_patient_id: '',
        cases: [],
        task: {
          type: 'Follow-up',
          title: '',
          description: ''
        },
        patient: {
          image: '',
          name_prefix_id: '',
          nick_name: '',
          first_name: '',
          last_name: '',
          dob: '',
          gender: '',
          country_id: '',
          nationality: '',
          id_card_number: '',
          passport_number: '',
          occupation: '',
          occupation_other: '',
          mobile: '',
          mobile_alternative: '',
          email: '',
          blood_group_id: '',
          congenital_disease: '',
          drug_allergy: '',
          note: '',
          status: '1',
          address: {
            country_id: '',
            house_no: '',
            village: '',
            group: '',
            alley: '',
            road: '',
            province: '',
            district: '',
            sub_district: '',
            zip_code: '',
            phone: '',
          },
          emergencyContact: {
            show: false,
            name_prefix_id: '',
            name: '',
            relationship_id: '',
            relationship_other: '',
            mobile_country_code: '',
            mobile: '',
            mobile_alternative: '',
            email: '',
            country_id: '',
            house_no: '',
            village: '',
            group: '',
            alley: '',
            road: '',
            province: '',
            district: '',
            sub_district: '',
            zip_code: '',
            phone: '',
          },
        }
      }
    }
  },
  computed: {
    currentComponent() {
      if (this.currentStep === 1)
        return 'step-one';
      else if  (this.currentStep === 2)
        return 'step-two';
      else if  (this.currentStep === 3) {
        if (this.$route.name === 'case_requests_task_mapping')
          return 'step-three-task';
        else
          return 'step-three';
      } else
        return 'step-four';
    },
    currentPatient() {
      if (this.form.patient_type === '1')
        return this.form.currentPatient;
      else
        return this.form.patient;
    },
    canGoNext() {
      if (this.currentStep === this.totalStep) {
        return this.form.creatingNewVisit || this.form.visit_id;
      } else if (this.currentStep === 1) {
        return !this.v$.form.$invalid
      } else if (this.currentStep === 2) {
        return !this.v$.form.$invalid
      } else if (this.currentStep === 3) {
        return !this.v$.form.$invalid
      } else
        return true;
    }
  },
  created() {
    this.loadDoctors();
    this.loadCaseRequest();
  },
  methods: {
    loadDoctors() {
      this.doctors = [];

      this.axios.get('/doctors')
        .then(res => {
          this.doctors = res.data.data;
        })
    },
    prevStep() {
      this.currentStep--;
    },
    loadCaseRequest() {
      this.caseRequest = null;

      this.axios.get('/case-requests/' + this.$route.params.id)
        .then(res => {
          this.caseRequest = res.data.data;
          this.form.task.description = this.caseRequest.koalendar_description.replaceAll('<br>', '\r\n')
        })
    },
    nextStep() {
      this.currentStep++;
    },
    submit() {
      this.allErrors = null;
      this.loading = true;

      if (this.$route.name === 'case_requests_task_mapping')
        this.form.request_type = 'task';
      else
        this.form.request_type = 'case';

      let url = '/case-requests/approve/' + this.$route.params.id;

      this.axios.post(url, this.form)
        .then(() => {
          this.currentStep++;
          this.loadCaseRequest();
        })
        .catch((err) => {
          this.allErrors = err.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  }
}
</script>