<template>
  <div class="box">
    <div class="box-header small-box-header">
      <h4 class="box-title">Task Details</h4>
    </div>
    <div class="box-body">
      <div class="form-group form-group-sm row mb-0">
        <label class="col-sm-2 col-form-label">Type</label>
        <div class="col-sm-10">
          <select class="form-control form-control-sm mb-2"
                  v-model="form.task.type">
            <option value="Follow-up">Follow-up</option>
            <option value="Consult">Consult</option>
          </select>
        </div>
      </div>
      <div class="form-group form-group-sm row mb-0">
        <label class="col-sm-2 col-form-label">Title</label>
        <div class="col-sm-10">
          <input type="text"
                 v-model="form.task.title"
                 class="form-control form-control-sm mb-2" />
        </div>
      </div>
      <div class="form-group form-group-sm row mb-0">
        <label class="col-sm-2 col-form-label">Description</label>
        <div class="col-sm-10">
          <textarea v-model="form.task.description"
                    rows="10"
                    class="form-control form-control-sm mb-2" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['formData', 'errors'],
  data() {
    return {
      form: {}
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    errors: {
      handler(newValue) {
        this.allErrors = newValue;
      },
      deep: true
    },
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    },
  }
}
</script>