<template>
  <h4>Add New Request</h4>

  <div class="row mt-3 mb-3">
    <div class="col-12">
      <button :class="'btn btn-sm me-2 ' + (currentStep === 1 ? 'btn-primary' : 'btn-secondary')">1. ประเภทลูกค้า</button>
      <button :class="'btn btn-sm me-2 ' + (currentStep === 2 ? 'btn-primary' : 'btn-secondary')">2. เลือกบริการ</button>
      <button :class="'btn btn-sm me-2 ' + (currentStep === 3 ? 'btn-primary' : 'btn-secondary')">3. เลือก Doctor Slot</button>
      <button :class="'btn btn-sm ' + (currentStep === 4 ? 'btn-primary' : 'btn-secondary')">4. เลือก Visit</button>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="box">
        <div class="box-body p-0">
          <component v-bind:is="currentComponent"
                     :currentPatient="currentPatient"
                     :errors="allErrors"
                     @submit="submit"
                     @nextStep="nextStep"
                     :formData="form" />
        </div>
        <div class="box-footer p-3">
          <div class="row">
            <div class="col-6">
              <router-link :to="{name: 'iframe_requests'}"
                           class="btn btn-sm btn-secondary">ยกเลิกการสร้าง</router-link>
            </div>
            <div class="col-6 text-end">
              <button type="button"
                      @click="prevStep"
                      :disabled="currentStep === 1"
                      :class="['btn me-3 btn-sm', {'btn-info-light': currentStep === 1}, {'btn-primary-light': currentStep !== 1}]">
                <i class="fas fa-arrow-left"></i> ย้อนกลับ
              </button>
              <button type="button"
                      @click="nextStep"
                      v-if="currentStep !== 4"
                      :disabled="!canGoNext"
                      :class="['btn btn-sm', {'btn-info-light': currentStep === 4}, {'btn-primary': currentStep !== 4}]">
                ถัดไป <i class="fas fa-arrow-right"></i>
              </button>
              <button type="button"
                      v-else
                      @click="submit"
                      :disabled="loading"
                      class="btn btn-primary btn-sm">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StepOne from '@/views/appointment/newVisitModal/steps/1/StepOne.vue';
import StepTwo from './Step2/Step2.vue';
import StepThree from './Step3/Step3.vue';
import StepFour from './Step4/Step4.vue';
import useVuelidate from "@vuelidate/core";
import {required, minLength} from "@vuelidate/validators";

export default {
  components: {StepOne, StepTwo, StepThree, StepFour},
  data() {
    return {
      currentStep: 1,
      modal: null,
      form: {},
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    if (this.currentStep === 1) {
      if (this.form.patient_type === '1') {
        return {
          form: {
            selected_patient_id: {required},
            opportunity_id: {required},
          },
        }
      } else {
        return {
          form: {
            patient: {
              name_prefix_id: {required},
              first_name: {required},
              last_name: {required},
              mobile: {required},
              blood_group_id: {required},
              congenital_disease: {required},
              drug_allergy: {required},
              dob: {required},
            }
          }
        }
      }
    } else if (this.currentStep === 2) {
      return {
        form: {
          cases: [{
            services: { required, minLength: minLength(1), }
          }]
        },
      }
    } else if (this.currentStep === 3) {
      return {
        form: {
          cases: [{
            doctor: {
              id: {required}
            },
            slot: { required },
            date: { required }
          }]
        },
      }
    }
  },
  computed: {
    currentPatient() {
      if (this.form.patient_type === '1')
        return this.form.currentPatient;
      else
        return this.form.patient;
    },
    currentComponent() {
      if (this.currentStep === 1)
        return 'step-one';
      else if  (this.currentStep === 2)
        return 'step-two';
      else if  (this.currentStep === 3)
        return 'step-three';
      else if  (this.currentStep === 4)
        return 'step-four';
      else
        return 'step-five';
    },
    canGoNext() {
      if (this.currentStep === 1) {
        return !this.v$.form.$invalid
      } else if (this.currentStep === 2) {
        return !this.v$.form.$invalid
      } else if (this.currentStep === 3) {
        return !this.v$.form.$invalid
      } else
        return true;
    }
  },
  created() {
    this.resetForm();
    this.$store.dispatch('iframeModule/loadServices');
  },
  methods: {
    resetForm() {
      this.currentStep = 1;
      this.form = {
        currentPatient: null,
        opportunity_id: this.$route.query.opportunityId,
        visit_id: '',
        is_request: true,
        date: '',
        patient_type: this.$route.query.patient_id ? '1' : '2',
        selected_patient_id: this.$route.query.patient_id,
        cases: [
          {
            id: 'new_' + Math.floor(Math.random() * 9999999999999),
            doctor: {
              id: ''
            },
            slot: '',
            services: [],
            date: ''
          }
        ],
        patient: {
          image: '',
          name_prefix_id: '',
          nick_name: '',
          first_name: '',
          last_name: '',
          dob: '',
          gender: '',
          country_id: '',
          nationality: '',
          id_card_number: '',
          passport_number: '',
          occupation: '',
          occupation_other: '',
          mobile: '',
          mobile_alternative: '',
          email: '',
          blood_group_id: '',
          congenital_disease: '',
          drug_allergy: '',
          note: '',
          status: '1',
          address: {
            country_id: '',
            house_no: '',
            village: '',
            group: '',
            alley: '',
            road: '',
            province: '',
            district: '',
            sub_district: '',
            zip_code: '',
            phone: '',
          },
          emergencyContact: {
            show: false,
            name_prefix_id: '',
            name: '',
            relationship_id: '',
            relationship_other: '',
            mobile_country_code: '',
            mobile: '',
            mobile_alternative: '',
            email: '',
            country_id: '',
            house_no: '',
            village: '',
            group: '',
            alley: '',
            road: '',
            province: '',
            district: '',
            sub_district: '',
            zip_code: '',
            phone: '',
          },
        }
      };
    },
    nextStep() {
      if (this.currentStep === 1) {
        this.form.date = '';
        this.form.visit_id = '';
      }

      this.currentStep++;
    },
    prevStep() {
      if (this.currentStep === 3) {
        this.form.date = '';
        this.form.visit_id = '';
      }

      this.currentStep--;
    },
    submit() {
      this.allErrors = null;
      this.loading = true;

      let url = '/visits';

      this.axios.post(url, this.form)
        .then(() => {
          this.$router.push({name: 'iframe_requests'});
        })
        .catch((err) => {
          this.allErrors = err.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  }
}
</script>