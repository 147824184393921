<template>
  <div class="modal fade"
       id="lock-case-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content" v-if="caseObj">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">
            Summary : {{ caseObj.case_no }}
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body p-2">
          <table class="table">
            <thead>
            <tr>
              <th>Services</th>
              <th>Staff</th>
              <th>Consumable</th>
              <th>Prescription</th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="i in maxRows" :key="'item_'+i">
              <td>
                <span v-if="caseObj.services.length >= i">{{ caseObj.services[i - 1].name_th }}</span>
              </td>
              <td>
                <span v-if="caseObj.staffs.length >= i">{{ caseObj.staffs[i - 1].user.full_name }}</span>
              </td>
              <td>
                <span v-if="caseObj.consumeProducts.length >= i">{{ caseObj.consumeProducts[i - 1].name_sale }}</span>
              </td>
              <td>
                <span v-if="caseObj.prescriptionProducts.length >= i">{{ caseObj.prescriptionProducts[i - 1].product.name_sale }}</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer text-center">
          <button type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal">ยกเลิก</button>

          <button class="btn btn-success"
                  :disabled="loading"
                  @click="submit">ถัดไป</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
export default {
  computed: {
    maxRows() {
      if (this.caseObj) {
        let numbers = [];

        numbers.push(this.caseObj.services.length);
        numbers.push(this.caseObj.staffs.length);
        numbers.push(this.caseObj.consumeProducts.length);
        numbers.push(this.caseObj.prescriptionProducts.length);

        return window._.max(numbers);
      }

      return 0;
    }
  },
  data() {
    return {
      modal: null,
      caseObj: null
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('lock-case-modal'));
  },
  methods: {
    show(id) {
      this.loadCase(id);

      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    loadCase(id) {
      this.caseObj = null;

      this.axios.get('/visit-cases/' + id)
        .then(res => {
          let c = res.data.data;

          let prescriptionProducts = [];

          c.prescriptions.forEach(p => {
            p.products.forEach(product => {
              prescriptionProducts.push(product);
            })
          })

          let consumeProducts = c.consumes.map(c => c.product);

          c.prescriptionProducts = prescriptionProducts;
          c.consumeProducts = consumeProducts;

          this.caseObj = c;
        })
    },
    submit() {
      this.$swal({
        title: 'Confirm finished case?',
        text: "“" + this.caseObj.case_no + "” will be locked and no longer editable.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ยกเลิก',
        cancelButtonText: 'ยืนยัน',
        confirmButtonColor: '#0D6EFD',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.post('/visit-cases/finish/' + this.caseObj.id)
            .then(() => {
              this.hide();
              this.$emit('updated');
            })
        }
      });
    }
  }
}
</script>