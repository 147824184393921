<template>
  <div class="row" v-if="caseObj">
    <div class="col-md-6"></div>

    <div class="col-md-6 text-end">
      <button class="btn btn-sm btn-info"
              @click="$refs.addServiceModal.show()">
        <i class="fas fa-plus"></i> Add Service
      </button>
    </div>
  </div>

  <hr>

  <div class="table-responsive">
    <table class="table table-small" v-if="caseObj">
      <thead>
      <tr>
        <th>Service No.</th>
        <th>ชื่อบริการ</th>
        <th>จำนวน</th>
        <th>หน่วยนับ</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(service, i) in caseObj.services"
          :key="'service_'+service.id">
        <td>{{ i+1 }}</td>
        <td>{{ service.name_th }}</td>
        <td>1.00</td>
        <td>{{ service.serviceUnit ? service.serviceUnit.name_th : '' }}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <add-service-modal ref="addServiceModal" />
</template>

<script>
import {mapGetters} from "vuex";
import AddServiceModal from "@/views/case/modals/AddServiceModal.vue";

export default {
  components: {AddServiceModal},
  computed: {
    ...mapGetters({
      caseObj: 'caseModule/getCaseObj'
    })
  }
}
</script>