<template>
  <div class="modal fade bs-example-modal-lg"
       id="prescription-add-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-lg modal-xl modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">
            สั่งยา/ผลิตภัณฑ์
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <component :formData="form" v-bind:is="currentComponent" />
        </div>
        <div class="modal-footer">
          <div class="row">
            <div class="col-md-6">
              <button class="btn btn-secondary"
                      type="button"
                      data-bs-dismiss="modal">ยกเลิกการสั่งยา/ผลิตภัณฑ์</button>
            </div>
            <div class="col-md-6 text-end">
              <button type="button"
                      @click="currentStep -= 1"
                      :disabled="currentStep === 1"
                      :class="['btn me-3', {'btn-info-light': currentStep === 1}, {'btn-primary': currentStep !== 1}]">
                <i class="fas fa-arrow-left"></i> ย้อนกลับ
              </button>
              <button type="button"
                      :disabled="!form.product_ids.length"
                      @click="currentStep += 1"
                      v-if="currentStep !== 2"
                      class="btn me-3 btn-primary">
                ถัดไป <i class="fas fa-arrow-right"></i>
              </button>
              <button type="button"
                      @click="submit"
                      :disabled="!form.product_ids.length || loading"
                      v-else
                      class="btn btn-primary">
                สั่งยา/ผลิตภัณฑ์
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import {mapGetters} from "vuex";
export default {
  components: {StepOne, StepTwo},
  data() {
    return {
      oldPrescription: null,
      currentStep: 1,
      modal: null,
      categories: [],
      form: {
        case_id: this.$route.params.id,
        product_ids: [],
        products: []
      }
    }
  },
  computed: {
    ...mapGetters({
      products: 'prescriptionModule/getProducts'
    }),
    currentComponent() {
      if (this.currentStep === 1)
        return 'step-one';
      else
        return 'step-two';
    }
  },
  watch: {
    form: {
      handler(newValue) {
        newValue.product_ids.forEach(id => {
          let product = this.form.products.find(p => p.id == id);

          if (!product) {
            this.form.products.push({
              id: id,
              quantity: 1
            })
          }
        })

        newValue.products.forEach((product, i) => {
          if (!newValue.product_ids.includes(product.id))
            this.form.products.splice(i, 1);
        })
      },
      deep: true
    }
  },
  created() {
    this.$store.dispatch('prescriptionModule/loadProducts');
    this.$store.dispatch('prescriptionModule/loadProductCategories');
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('prescription-add-modal'));
  },
  methods: {
    reset() {
      this.form = {
        case_id: this.$route.params.id,
        product_ids: [],
        products: []
      };
    },
    show(prescription = null) {
      this.reset();

      if (prescription) {
        this.oldPrescription = {...prescription};

        this.form.product_ids = prescription.products.map(p => p.product_id);
        this.form.products = prescription.products.map(p => ({id: p.product_id, quantity: p.quantity}));
      } else {
        this.oldPrescription = null;
      }
      this.modal.show();
    },
    submit() {
      this.allErrors = null;
      this.loading = true;

      let url = '/prescriptions';

      if (this.oldPrescription) {
        url = '/prescriptions/' + this.oldPrescription.id
        this.form._method = 'PATCH'
      } else {
        delete this.form._method;
      }

      this.axios.post(url, this.form)
        .then(() => {
          this.modal.hide();
          this.$emit('added');
        })
        .catch((err) => {
          this.allErrors = err.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  }
}
</script>