<template>
  <div style="display: contents">
    <span class="badge badge-warning" v-if="status == 1">Request</span>
    <span class="badge badge-success" v-else-if="status == 2">Approved</span>
    <span class="badge badge-danger" v-else-if="status == 3">Rejected</span>
    <span class="badge badge-danger" v-else-if="status == 4">Cancelled</span>
  </div>
</template>

<script>
export default {
  props: ['status'],
}
</script>