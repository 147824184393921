<template>
  <div class="modal fade"
       id="add-new-service-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">
            Add New Service
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <select class="form-select"
                  v-model="form.service_id">
            <option value="">Select Service</option>
            <option :value="service.id"
                    v-for="service in services"
                    :key="'service_'+service.id">{{ service.name_th }}</option>
          </select>
        </div>
        <div class="modal-footer text-center">
          <button type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal">ยกเลิกการสร้าง</button>

          <button class="btn btn-success"
                  :disabled="loading"
                  @click="submit">บันทึก</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        service_id: ''
      },
      services: [],
      modal: null,
    }
  },
  created() {
    this.loadServices();
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('add-new-service-modal'));
  },
  methods: {
    show() {
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    loadServices() {
      this.services = [];

      this.axios.get('/services')
        .then(res => {
          this.services = res.data.data;
        })
    },
    submit() {
      if (this.form.service_id) {
        this.axios.post('/visit-cases/'+this.$route.params.id+'/service', this.form)
          .then(() => {
            window.location.reload();
          });
      }
    }
  }
}
</script>