<template>
  <div class="row">
    <div class="col-md-6">
      <h4>รายชื่อพนักงาน</h4>
    </div>

    <div class="col-md-6 text-end">
      <button class="btn btn-sm btn-info"
              @click="$refs.selectStaffModal.show()">
        <i class="fas fa-plus"></i> เพิ่มพนักงาน
      </button>
    </div>
  </div>

  <hr>

  <div class="row" v-if="caseObj">
    <div class="col-md-12">
      <div class="table-responsive">
        <table class="table table-small">
          <thead>
          <tr>
            <th>ชื่อ - นามสกุล (รหัสพนักงาน)</th>
            <th>ตำแหน่ง</th>
            <th>Primary</th>
            <th class="text-end">Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="staff in caseObj.staffs"
              :key="'staff_'+staff.id">
            <td>
              <div class="d-flex align-items-center">
                <div class="me-15">
                  <img src="/images/avatar-girl.png" class="h-40 w-40 rounded" />
                </div>
                <div class="d-flex flex-column flex-grow-1 fw-500">
                  <a href="#" class="text-dark hover-primary mb-1 fs-16">{{ staff.user.full_name }}</a>
                  <span class="text-fade">{{ staff.staff_id }}</span>
                </div>
              </div>
            </td>
            <td>{{ staff.type_text }}</td>
            <td></td>
            <td class="text-end">
              <a href="#" class="text-muted" @click.prevent="deleteData(staff)">
                <i class="fas fa-trash"></i> Delete
              </a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <select-staff-modal ref="selectStaffModal"
                      @addStaff="addStaff"
                      :staffs="staffs" />
</template>

<script>
import {mapGetters} from "vuex";
import SelectStaffModal from "@/views/staff/assign_case/components/SelectStaffModal";

export default {
  components:{SelectStaffModal},
  data() {
    return {
      staffs: []
    }
  },
  created() {
    this.loadStaffs();
  },
  computed: {
    ...mapGetters({
      caseObj: 'caseModule/getCaseObj'
    })
  },
  methods: {
    addStaff(staffId) {
      if (staffId) {
        this.axios.post('/visit-cases/'+this.$route.params.id+'/staff', {
            'staff_id': staffId
          })
          .then(() => {
            window.location.reload();
          });
      }
    },
    loadStaffs() {
      this.staffs = [];

      this.axios.get('/staffs')
        .then(res => {
          this.staffs = res.data.data;
        })
    },
    deleteData(row) {
      this.$swal({
        title: 'Are you sure want to delete?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        confirmButtonColor: '#dd4b39',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete('/visit-cases/'+this.$route.params.id+'/staff/' + row.id)
            .then(() => {
              window.location.reload();
            });
        }
      });
    },
  }
}
</script>