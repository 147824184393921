<template>
  <div class="box h-500 bg-gray-300">
    <div class="box-body d-flex align-items-center">
      <div class="text-center w-p100">
        <i class="fas fa-clipboard fs-100 text-black-50 text-info"></i>
        <h4 class="mt-3 text-info">No Visit Yet!</h4>
        <button class="btn btn-sm btn-primary-light"
                v-if="!creatingNewVisit"
                @click="createVisit">Create Visit</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['creatingNewVisit'],
  methods: {
    createVisit() {
      this.$emit('createVisit');
    }
  }
}
</script>