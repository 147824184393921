<template>
  <div class="box">
    <div class="box-header small-box-header text-end">
      <router-link :to="{name: 'iframe_requests_new'}"
         class="btn btn-sm btn-primary">+ Add New</router-link>
    </div>
    <div class="box-body p-3">
      <table-component :columns="columns"
                       :rows="requests"
                       :hasSubTable="true"
                       @sorting="sorting"
                       :pagination="pagination"
                       @search="search"
                       :search="false"
                       @pageChanged="pageChanged">

        <template v-slot:subTable="{ row, index }">
          <tr :class="['bg-secondary-light', {'d-none': activeRowIndex !== index}]">
            <td :colspan="columns.length" class="p-0">
              <table class="table m-0">
                <tbody>
                <tr v-for="(item, ii) in row.cases" :key="'item_'+ii">
                  <td>Case No. {{ item.case_no }} - {{ item.services.map(s => s.name_th).join(', ') }}</td>
                </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </template>

        <template v-slot:request_no="{ row, index }">
          <a href="#"
             @click.prevent="changeSelectedRowIndex(index)">
            <span v-if="activeRowIndex === index"><i class="fas fa-minus"></i></span>
            <span v-else><i class="fas fa-plus"></i></span>
            {{ row.request_no }}
          </a>
        </template>

        <template v-slot:status="{ row }">
          <appointment-status :status="row.status" />
        </template>

        <template v-slot:doctors="{ row }">
          <img :src="c.doctor.image ? c.doctor.image : '/images/avatar-girl.png'"
               onerror="this.src='/images/avatar-girl.png'"
               class="h-30 w-30 rounded me-2"
               v-for="c in row.cases"
               :key="'case_'+c.id">
        </template>
      </table-component>
    </div>
  </div>
</template>

<script>
import TableComponent from "@/components/TableComponent";
import AppointmentStatus from "@/views/appointment/AppointmentStatus.vue";
export default {
  components: {TableComponent, AppointmentStatus},
  data() {
    return {
      requests: [],
      activeRowIndex: null,
      pagination: null,
      queryParams: {
        opportunity_id: this.$route.query.opportunityId ? this.$route.query.opportunityId : 'no',
        status: '',
        is_request: 1,
        page: 1,
        search: '',
        sort: 'created_at',
        sort_order: 'desc',
        per_page: 10
      },
      sortable: {
        order: 'created_at',
        sort: 'desc'
      },
      columns: [
        {
          label: "Request",
          field: "request_no",
          sortable: true,
          searchable: true,
          slot: true,
        },
        {
          label: "Visit No.",
          field: "visit_no",
          sortable: true,
          searchable: true,
        },
        {
          label: "Doctor(s)",
          field: "doctors",
          sortable: false,
          searchable: false,
          slot: true
        },
        {
          label: "Date",
          field: 'date',
          sortable: true,
          searchable: false,
        },
        {
          label: "No. of Cases",
          field: 'case',
          sortable: false,
          searchable: false,
          display(row) {
            return row.cases.length;
          }
        },
        {
          label: "สถานะ",
          field: 'status',
          sortable: true,
          searchable: false,
          slot: true
        },
      ],
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.requests = [];
      this.activeRowIndex = null;

      this.axios.get('/visits', {params: this.queryParams})
        .then((response) => {
          this.requests = response.data.data;
          this.pagination = response.data.meta;
        })
    },
    changeSelectedRowIndex(index) {
      if (this.activeRowIndex === index)
        this.activeRowIndex = null;
      else {
        this.activeRowIndex = index
      }
    },
  }
}
</script>