<template>
  <div class="row">
    <div class="col-md-6">
      <h4>วัสดุสิ้นเปลือง</h4>
    </div>

    <div class="col-md-6 text-end">
      <button class="btn btn-sm btn-info me-2"
              @click="$refs.selectProductModal.show()">
        <i class="fas fa-plus"></i> เพิ่มรายการ
      </button>
      <button class="btn btn-sm btn-primary"><i class="fas fa-barcode"></i> สแกนบาร์โค้ด</button>
    </div>
  </div>

  <hr>

  <div class="row">
    <div class="col-md-12">
      <div class="table-responsive">
        <table class="table table-small">
          <thead>
          <tr>
            <th>ลำดับ</th>
            <th>รหัสสินค้า</th>
            <th>ชื่อสินค้า</th>
            <th>ราคา</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, i) in consumables" :key="'item_'+item.id">
            <td>{{ i+1 }}</td>
            <td>{{ findProductAttribute(item.product_id, 'product_id') }}</td>
            <td>{{ findProductAttribute(item.product_id, 'product_name') }}</td>
            <td>{{ findProductAttribute(item.product_id, 'sell_price') }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <select-product-modal @selected="addProduct"
                        ref="selectProductModal" />
</template>

<script>
import SelectProductModal from "@/components/modals/SelectProductModal.vue";
export default {
  components: {SelectProductModal},
  data() {
    return {
      consumables: [],
      products: []
    }
  },
  async created() {
    await this.loadProducts();

    setTimeout(() => {
      this.loadConsumables();
    }, 200)
  },
  methods: {
    findProductAttribute(id, attribute) {
      let product = this.products.find(p => p.product_id === id);

      if (product) {
        return product[attribute];
      }

      return '';
    },
    loadConsumables() {
      this.consumables = [];

      this.axios.get('/visit-cases/consume/' + this.$route.params.id)
        .then(res => {
          this.consumables = res.data.data;
        })
    },
    async loadProducts() {
      this.products = [];

      await this.axios.get('/inventory/products')
        .then(res => {
          this.products = res.data;
        })
    },
    addProduct(productId) {
      if (productId) {
        this.axios.post('/visit-cases/consume/' + this.$route.params.id, {
            product_id: productId
          })
          .then(() => this.loadConsumables())
      }
    }
  }
}
</script>