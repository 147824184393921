<template>
  <div class="box">
    <div class="box-header small-box-header">
      <h4 class="box-title">Doctor</h4>
    </div>
    <div class="box-body">
      <div class="row">
        <div class="col-6 col-lg-3" v-for="doctor in doctors"
             :key="'doctor_'+doctor.id">
          <div class="box ribbon-box">
            <div class="box-body text-center">
              <img :src="doctor.image ? doctor.image : '/images/avatar-girl.png'"
                   onerror="this.src='/images/avatar-girl.png'"
                   class="h-150">
              <div class="text-center">
                <h3 class="my-10">
                  <a href="#" @click.prevent="selectDoctor(doctor)">{{ doctor.full_name }}</a>
                </h3>
                <h6 class="user-info mt-0 mb-10 text-fade">{{ doctor.department.name }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['doctors', 'formData', 'errors'],
  data() {
    return {
      form: {}
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    errors: {
      handler(newValue) {
        this.allErrors = newValue;
      },
      deep: true
    },
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    },
  },
  methods: {
    selectDoctor(doctor) {
      this.form.doctor_id = doctor.id;
      this.form.doctor = doctor;
      this.$emit('nextStep');
    }
  }
}
</script>