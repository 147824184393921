<template>
  <div class="content-header pb-0" v-if="caseObj">
    <div class="row">
      <div class="col-6">
        <h3 class="page-title">Case Details : {{ caseObj.case_no }}</h3>
      </div>
      <div class="col-6 text-end">
        <select v-if="editingStatus"
                v-model="statusForm.status"
                class="form-select form-select-sm d-inline w-auto">
          <option value="1">Request</option>
          <option value="6">Approved</option>
          <option value="2">Open</option>
          <option value="3">Ongoing</option>
          <option value="4">Finished</option>
          <option value="5">Cancel</option>
        </select>

        <case-status :id="caseObj.id" :status="caseObj.status" v-else />

        <button class="btn btn-sm btn-secondary ms-2"
                v-if="!editingStatus && caseObj.status !== 1"
                @click="editStatus">
          <i class="fas fa-edit"></i>
        </button>

        <button class="btn btn-sm btn-secondary ms-2"
                v-if="!editingStatus && caseObj.status === 1"
                @click="$refs.confirmCaseModal.show(caseObj)">
          <i class="fas fa-cog"></i>
        </button>

        <button class="btn btn-sm btn-success ms-2"
                v-if="editingStatus"
                @click="saveStatus">
          <i class="fas fa-save"></i>
        </button>
      </div>
    </div>
  </div>

  <hr>

  <div class="row" v-if="caseObj">
    <div class="col-xl-4 col-12">
      <div class="box">
        <div class="box-header small-box-header">
          <h4 class="box-title">Assigned Doctor</h4>
        </div>
        <div class="box-body p-3">
          <div class="d-flex align-items-center">
            <div class="me-15">
              <img src="/images/avatar-2.png" class="avatar avatar-lg rounded10">
            </div>
            <div class="d-flex flex-column flex-grow-1 fw-500">
              <a href="#" class="text-dark hover-primary mb-1 fs-16">{{ caseObj.doctor.full_name }}</a>
              <span class="text-fade">{{ caseObj.doctor.department.name }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="box-header small-box-header">
          <h4 class="box-title">ข้อมูลผู้รับบริการ</h4>
        </div>
        <div class="box-body p-3">
          <div class="row">
            <div class="col-md-8">
              <table class="patient-details-table table table-borderless table-sm">
                <tbody>
                <tr>
                  <td>
                    <span style="color:#5156BE;">◆</span> HN
                  </td>
                  <td>: {{ caseObj.patient.hn }}</td>
                </tr>
                <tr>
                  <td>
                    <span style="color:#5156BE;">◆</span> รหัสลูกค้า
                  </td>
                  <td>: {{ caseObj.patient.patient_id }}</td>
                </tr>
                <tr>
                  <td>
                    <span style="color:#5156BE;">◆</span> ชื่อลูกค้า
                  </td>
                  <td>: {{ caseObj.patient.full_name }}</td>
                </tr>
                <tr>
                  <td>
                    <span style="color:#5156BE;">◆</span> ชื่อเล่น
                  </td>
                  <td>: {{ caseObj.patient.nick_name }}</td>
                </tr>
                <tr>
                  <td>
                    <span style="color:#5156BE;">◆</span> อายุ
                  </td>
                  <td>: {{ caseObj.patient.age }} ปี</td>
                </tr>
                <tr>
                  <td>
                    <span style="color:#5156BE;">◆</span> หมู่เลือด
                  </td>
                  <td>: {{ caseObj.patient.bloodGroup.name }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-4 text-end">
              <img :src="caseObj.patient.image ? caseObj.patient.image : '/images/avatar-girl.png'"
                   onerror="this.src='/images/avatar-girl.png'"
                   class="w-p100 rounded">
            </div>
          </div>


          <table class="patient-details-table table table-borderless table-sm">
            <tbody>
            <tr>
              <td class="pt-3">
                <span style="color:#5156BE;">◆</span> โรคประจำตัว :
              </td>
              <td class="pt-3 text-end">
                <a href="#"
                   v-if="!editingCongenitalDisease"
                   @click.prevent="editCongenitalDisease">
                  <i class="fas fa-edit"></i> Edit
                </a>
                <a href="#"
                   v-else
                   @click.prevent="saveCongenitalDisease">
                  <i class="fas fa-save"></i> Save
                </a>
              </td>
            </tr>
            <tr>
              <td class="pt-1" colspan="2">
                <textarea class="form-control"
                          v-model="congenitalDiseaseForm.congenital_disease"
                          rows="3"
                          :disabled="!editingCongenitalDisease"></textarea>
              </td>
            </tr>
            <tr>
              <td class="pt-3">
                <span style="color:#5156BE;">◆</span> ประวัติการแพ้ยาและอาหาร :
              </td>
              <td class="pt-3 text-end">
                <a href="#"
                   v-if="!editingDrugAllergy"
                   @click.prevent="editDrugAllergy">
                  <i class="fas fa-edit"></i> Edit
                </a>
                <a href="#"
                   v-else
                   @click.prevent="saveDrugAllergy">
                  <i class="fas fa-save"></i> Save
                </a>
              </td>
            </tr>
            <tr>
              <td class="pt-1" colspan="2">
                <textarea class="form-control"
                          v-model="drugAllergyForm.drug_allergy"
                          rows="3"
                          :disabled="!editingDrugAllergy"></textarea>
              </td>
            </tr>
            <tr>
              <td colspan="2" class="text-center pt-3">
                <router-link :to="{name: 'patients_details', params: {id: caseObj.patient.id}}"
                             class="btn btn-info-light btn-sm me-2">ดูรายละเอียด</router-link>
                <button type="button" class="waves-effect waves-light btn btn-primary-light btn-sm">Facial 3D</button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="box">
        <div class="box-header small-box-header">
          <div class="row">
            <div class="col-md-6">
              <h4 class="box-title">Other Cases</h4>
            </div>
            <div class="col-md-6 text-end">
              <router-link :to="{name: 'visit_details_cases', params: {id: caseObj.visit_id}}">See visit <i class="fas fa-chevron-right"></i></router-link>
            </div>
          </div>
        </div>
        <div class="box-body p-3">
          <div class="inner-user-div3">
            <div :class="['d-flex align-items-center', {'mb-30': otherCases.length !== i+1}]"
                 v-for="(c, i) in otherCases"
                 :key="'case_'+c.id">
              <div class="me-15">
                <img src="/images/avatar-2.png" class="avatar avatar-lg rounded10 bg-primary-light" alt="" />
              </div>
              <div class="d-flex flex-column flex-grow-1 fw-500">
                <a href="#" class="text-dark hover-primary mb-1 fs-16">{{ c.doctor.full_name }}</a>
                <span class="text-fade">{{ c.services.map(s => s.name_th).join(', ') }}</span>
              </div>
              <span class="badge badge-info-light">Inprogress</span>
            </div>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="box-header small-box-header with-border">
          <div class="row d-flex align-items-center">
            <div class="col">
              <h4 class="box-title">Vitals</h4>
            </div>
          </div>
        </div>
        <div class="box-body p-3">
          <table class="patient-details-table table table-borderless table-sm">
            <tr>
              <td>
                <span style="color: #5156BE">◆</span> Body Temperature
              </td>
              <td>: {{ vitals ? vitals.temperature : '' }}</td>
            </tr>
            <tr>
              <td>
                <span style="color: #5156BE">◆</span> Pulse Rate
              </td>
              <td>: {{ vitals ? vitals.pulse : '' }}</td>
            </tr>
            <tr>
              <td>
                <span style="color: #5156BE">◆</span> Respiration Rate
              </td>
              <td>: {{ vitals ? vitals.respiration : '' }}</td>
            </tr>
            <tr>
              <td>
                <span style="color: #5156BE">◆</span> Blood Pressure
              </td>
              <td>: {{ vitals ? vitals.blood_pressure : '' }}</td>
            </tr>
            <tr>
              <td>
                <span style="color: #5156BE">◆</span> Sp O2
              </td>
              <td>: {{ vitals ? vitals.sp_o2 : '' }}</td>
            </tr>
            <tr>
              <td>
                <span style="color: #5156BE">◆</span> Height
              </td>
              <td>: {{ vitals ? vitals.height : '' }}</td>
            </tr>
            <tr>
              <td>
                <span style="color: #5156BE">◆</span> Weight
              </td>
              <td>: {{ vitals ? vitals.weight : '' }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="box">
        <div class="box-header small-box-header with-border">
          <div class="row d-flex align-items-center">
            <div class="col">
              <h4 class="box-title">Portrait</h4>
            </div>
            <div class="col text-end">
              <button class="btn-sm me-2 btn btn-info-light"
                      @click="$refs.portraitInput.click()">
                <i class="fas fa-upload"></i> อัปโหลด
              </button>
              <button class="btn-sm waves-effect waves-light btn btn-info-light">
                <i class="fas fa-camera"></i> ถ่ายภาพ
              </button>
            </div>
          </div>
        </div>
        <div class="box-body p-3">
          <div class="row">
            <div class="col-6"
                 v-for="portrait in filteredPortraits"
                 :key="'portrait_'+portrait.id">
              <img class="rounded max-h-200 mb-3" :src="portrait.image">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-8 col-12">
      <div class="row mb-3">
        <div class="col-12">
          <router-link :class="['waves-effect btn-sm waves-light btn btn-primary me-10', {'btn-outline': $route.name !== 'case_notes'}]"
                       :to="{name: 'case_notes', params: {id: $route.params.id}}">Notes</router-link>
          <router-link :class="['waves-effect btn-sm waves-light btn btn-primary me-10', {'btn-outline': $route.name !== 'case_nurse_notes'}]"
                       :to="{name: 'case_nurse_notes', params: {id: $route.params.id}}">Nurse's Note</router-link>
          <router-link :class="['waves-effect btn-sm waves-light btn btn-primary me-10', {'btn-outline': $route.name !== 'case_services'}]"
                       :to="{name: 'case_services', params: {id: $route.params.id}}">Services</router-link>
          <router-link :class="['waves-effect btn-sm waves-light btn btn-primary me-10', {'btn-outline': $route.name !== 'case_staff'}]"
                       :to="{name: 'case_staff', params: {id: $route.params.id}}">Staff</router-link>
          <router-link :class="['waves-effect btn-sm waves-light btn btn-primary me-10', {'btn-outline': $route.name !== 'case_consumable'}]"
                       :to="{name: 'case_consumable', params: {id: $route.params.id}}">Consumable</router-link>
          <router-link :class="['waves-effect btn-sm waves-light btn btn-primary me-10', {'btn-outline': $route.name !== 'case_prescription'}]"
                       :to="{name: 'case_prescription', params: {id: $route.params.id}}">Prescription</router-link>
          <router-link :class="['waves-effect btn-sm waves-light btn btn-primary me-10', {'btn-outline': $route.name !== 'case_follow_up'}]"
                       :to="{name: 'case_follow_up', params: {id: $route.params.id}}">Follow-Up</router-link>
          <router-link :class="['waves-effect btn-sm waves-light btn btn-primary me-10', {'btn-outline': $route.name !== 'case_documents'}]"
                       :to="{name: 'case_documents', params: {id: $route.params.id}}">Document</router-link>
        </div>
      </div>

      <router-view />
    </div>
  </div>

  <input @change="readPortraitURL($event.target)"
         type="file"
         accept="image/*"
         ref="portraitInput" style="display: none">

  <confirm-case-modal ref="confirmCaseModal" />
</template>

<script>
import CaseStatus from "@/views/case/CaseStatus.vue";
import ConfirmCaseModal from "@/views/case/modals/ConfirmCaseModal.vue";
export default {
  components: {CaseStatus, ConfirmCaseModal},
  data() {
    return {
      caseObj: null,
      otherCases: [],
      portraits: [],
      vitals: null,
      editingStatus: false,
      editingCongenitalDisease: false,
      editingDrugAllergy: false,
      statusForm: {
        status: ''
      },
      congenitalDiseaseForm: {
        congenital_disease: '',
      },
      drugAllergyForm: {
        drug_allergy: '',
      }
    }
  },
  created() {
    this.loadCase();
  },
  computed: {
    filteredPortraits() {
      return this.portraits.filter(p => p.visit_case_id == this.$route.params.id);
    }
  },
  methods: {
    editStatus() {
      this.statusForm.status = this.caseObj.status;
      this.editingStatus = true;
    },
    saveStatus() {
      this.statusForm.visit_case_id = this.caseObj.id;

      this.axios.post('/visit-cases/change-status', this.statusForm)
        .then(() => {
          this.caseObj.status = this.statusForm.status;
          this.editingStatus = false;
        })
    },
    editCongenitalDisease() {
      this.congenitalDiseaseForm.congenital_disease = this.caseObj.patient.congenital_disease;
      this.editingCongenitalDisease = true;
    },
    saveCongenitalDisease() {
      this.congenitalDiseaseForm.patient_id = this.caseObj.patient.id;

      this.axios.post('/patients/congenital-disease', this.congenitalDiseaseForm)
        .then(() => {
          this.editingCongenitalDisease = false;
        })
    },
    editDrugAllergy() {
      this.drugAllergyForm.drug_allergy = this.caseObj.patient.drug_allergy;
      this.editingDrugAllergy = true;
    },
    saveDrugAllergy() {
      this.drugAllergyForm.patient_id = this.caseObj.patient.id;

      this.axios.post('/patients/drug-allergy', this.drugAllergyForm)
        .then(() => {
          this.editingDrugAllergy = false;
        })
    },
    loadCase() {
      this.caseObj = null;
      this.$store.commit('caseModule/setCaseObj', null);

      this.axios.get('/visit-cases/' + this.$route.params.id)
        .then(res => {
          this.caseObj = res.data.data;
          this.congenitalDiseaseForm.congenital_disease = this.caseObj.patient.congenital_disease;
          this.drugAllergyForm.drug_allergy = this.caseObj.patient.drug_allergy;
          this.$store.commit('caseModule/setCaseObj', this.caseObj);

          this.loadOtherCases(this.caseObj.visit_id);
          this.loadVitals(this.caseObj.visit_id);
          this.loadPortraits(this.caseObj.visit_id, this.$route.params.id);
        })
    },
    loadOtherCases(visitId) {
      this.otherCases = [];

      this.axios.get('/visit-cases?visit_id=' + visitId)
        .then(res => {
          this.otherCases = res.data.data.filter(c => c.id != this.$route.params.id);
        })
    },
    loadVitals(visitId) {
      this.vitals = null;

      this.axios.get('/visits/vitals/' + visitId)
        .then(res => {
          this.vitals = res.data.data;
        })
    },
    loadPortraits(visitId) {
      this.portraits = [];

      this.axios.get('/visits/portrait/' + visitId)
        .then(res => {
          this.portraits = res.data.data;
        })
    },
    readPortraitURL(input) {
      let self = this;

      if (input.files && input.files[0]) {
        let reader = new FileReader();

        reader.onload = function (e) {
          self.axios.post('/visits/portrait', {
              visit_id: self.caseObj.visit_id,
              visit_case_id: self.$route.params.id,
              patient_id: self.caseObj.patient_id,
              image: e.target.result
            })
            .then(res => {
              self.portraits = res.data.data;
            });
        }

        reader.readAsDataURL(input.files[0]);
      }
    },
  }
}
</script>