<template>
  <div class="box" v-if="visit">
    <div class="box-header small-box-header">
      <div class="row">
        <div class="col-6">
          <h4 class="box-title mb-0">Visit Details</h4>
        </div>
        <div class="col-6 text-end">
          <h4 class="box-title mb-0 text-end">Visit No.: {{ visit.visit_no }}</h4>
        </div>
      </div>
    </div>
    <div class="box-body p-3">
      <div class="row">
        <div class="col-2">
          <img v-if="form.patient_type === '1'"
               :src="form.currentPatient.image ? form.currentPatient.image : '/images/avatar-girl.png'"
               onerror="this.src='/images/avatar-girl.png'"
               class="w-p100">

          <img v-else
               :src="form.patient.image ? form.patient.image : '/images/avatar-girl.png'"
               onerror="this.src='/images/avatar-girl.png'"
               class="w-p100">
        </div>
        <div class="col-10">
          <h5>{{ visit.patient.full_name }} - EMR ID : {{ visit.patient.hn }}</h5>

          <div class="box" v-for="c in visit.cases"
               :key="'case_'+c.id">
            <div class="box-header small-box-header">
              <h4 class="box-title mb-0">
                Case No. : {{ c.case_no }} <case-status :status="c.status" />
              </h4>
            </div>
            <div class="box-body p-1">
              <table class="table table-borderless table-small text-muted">
                <tr>
                  <td>
                    <span style="color:#5156BE;">◆</span> Doctor
                  </td>
                  <td>: {{ c.doctor.full_name }}</td>
                </tr>
                <tr>
                  <td>
                    <span style="color:#5156BE;">◆</span> Services
                  </td>
                  <td>: {{ c.services.map(s => s.name_th).join(', ') }}</td>
                </tr>
                <tr>
                  <td>
                    <span style="color:#5156BE;">◆</span> Slot
                  </td>
                  <td>: {{ c.doctorSlot.date_format }}</td>
                </tr>
              </table>
            </div>
          </div>

          <div class="row">
            <div class="col-12 text-center">
              <button class="btn btn-sm btn-primary"
                      @click="$emit('selectVisit', visit)">Add Request to This Visit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CaseStatus from "@/views/case/CaseStatus.vue";
export default {
  props: ['formData', 'visit'],
  components: {CaseStatus},
  data() {
    return {
      form: {},
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
}
</script>