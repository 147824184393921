<template>
  <div class="modal fade"
       id="confirm-case-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">
            Request Management
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body p-2">
          <div class="form-group">
            <div class="radio">
              <input name="group1"
                     type="radio"
                     id="Option_1"
                     value="1"
                     v-model="form.approved">
              <label for="Option_1">Approve</label>
            </div>
            <div class="radio">
              <input name="group1"
                     type="radio"
                     id="Option_2"
                     v-model="form.approved"
                     value="0">
              <label for="Option_2">Reject</label>
            </div>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal">ยกเลิก</button>

          <button class="btn btn-success"
                  :disabled="loading"
                  @click="submit">บันทึก</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        approved: '1'
      },
      modal: null,
      caseObj: null
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('confirm-case-modal'));
  },
  methods: {
    show(caseObj) {
      this.caseObj = caseObj;

      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    submit() {
      this.axios.post('/visit-cases/'+this.caseObj.id+'/confirm', this.form)
        .then((res) => {
          this.caseObj.status = res.data.data.status;
          this.hide();
        });
    }
  }
}
</script>