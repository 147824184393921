<template>
  <div class="form-group" v-if="caseObj">
    <div class="row">
      <div class="col-6">
        <label class="form-label">{{ label }}</label>
      </div>
      <div class="col-6 text-end">
        <a href="#"
           v-if="!editing"
           @click.prevent="edit">
          <i class="fas fa-edit"></i> Edit
        </a>
        <a href="#"
           v-else
           @click.prevent="save">
          <i class="fas fa-save"></i> Save
        </a>
      </div>
    </div>

    <textarea rows="5"
              :disabled="!editing"
              class="form-control"
              v-model="form.value"></textarea>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: ['field', 'label'],
  data() {
    return {
      editing: false,
      form: {
        value: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      caseObj: 'caseModule/getCaseObj'
    })
  },
  mounted() {
    this.form.value = this.caseObj[this.field]
  },
  methods: {
    edit() {
      this.editing = true;
    },
    save() {
      this.form[this.field] = this.form.value;

      this.axios.post('/visit-cases/'+this.$route.params.id+'/notes', this.form)
        .then(() => {
          this.editing = false;
        })
    },
  }
}
</script>