<template>
  <table class="table table-sm">
    <thead>
    <tr>
      <th>รหัสสินค้า</th>
      <th>ชื่อสินค้า</th>
      <th>หน่วยนับ</th>
      <th class="text-end">ราคา</th>
      <th class="text-end">คงเหลือ</th>
      <th class="text-end">จำนวน</th>
      <th class="text-end">ราคารวม</th>
      <th class="text-end">ลบ</th>
    </tr>
    </thead>

    <tbody v-if="form.product_ids">
    <tr v-for="product in products.filter(p => form.product_ids.includes(p.product_id))"
        :key="'product_'+product.id">
      <td>{{ product.product_id }}</td>
      <td>{{ product.product_name }}</td>
      <td>{{ product.unit }}</td>
      <td class="text-end">{{ product.sell_price }}</td>
      <td class="text-end">{{ product.balance }}</td>

      <td class="text-end">
        <input type="text"
               class="text-center form-control form-control-sm"
               v-model="form.products.find(p => p.id == product.product_id).quantity">
      </td>
      <td class="text-end">{{ getTotal(parseFloat(product.sell_price), parseFloat(form.products.find(p => p.id == product.product_id).quantity)) }}</td>
      <td class="text-end" @click="removeProduct(product)">
        <i class="fas fa-trash text-muted"></i>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: ['formData'],
  data() {
    return {
      form: {},
    }
  },
  computed: {
    ...mapGetters({
      products: 'prescriptionModule/getProducts',
    })
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  methods: {
    getTotal(unitPrice, quantity) {
      if (isNaN(quantity))
        return 0;
      else
        return unitPrice * quantity;
    },
    removeProduct(product) {
      let index = this.form.product_ids.findIndex(id => id == product.id);

      if (index !== -1)
        this.form.product_ids.splice(index, 1);
    }
  }
}
</script>