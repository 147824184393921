<template>
  <div class="modal fade bs-example-modal-lg"
       id="add-new-case-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">
            Add New Case
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-4">
              <div v-if="patient">
                <div class="w-p100 text-center">
                  <img :src="patient.image ? patient.image : '/images/avatar-girl.png'"
                       onerror="this.src='/images/avatar-girl.png'"
                       class="max-h-100">
                </div>

                <table class="table table-borderless table-small mt-3">
                  <tr>
                    <td class="p-0">รหัสลูกค้า</td>
                    <td class="p-0">: {{ patient.patient_id }}</td>
                  </tr>
                  <tr>
                    <td class="p-0">HN</td>
                    <td class="p-0">: {{ patient.hn }}</td>
                  </tr>
                  <tr>
                    <td class="p-0">การใช้งาน</td>
                    <td class="form-group">
                      <div class="c-inputs-stacked">
                        :
                        <input name="group123" type="radio" id="radio_123" value="1" disabled :checked="patient.status === 1">
                        <label for="radio_123" class="me-10">เปิด</label>
                        <input name="group123" type="radio" id="radio_456" value="0" disabled :checked="patient.status === 0">
                        <label for="radio_456" class="me-10">ปิด</label>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="col-md-8">
              <div class="form-group form-group-sm row mb-2">
                <label class="col-sm-3 col-form-label">ระบุลูกค้า</label>
                <div class="col-sm-9">
                  <select class="form-control form-control-sm select2 customer-select2"
                          style="width: 100%;">
                    <option value=""></option>
                    <option :value="patient.id"
                            v-for="patient in patients"
                            :key="'patient_'+patient.id">{{ patient.full_name }}</option>
                  </select>
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('date')}]">
                <label class="col-sm-3 col-form-label">Date</label>
                <div class="col-sm-9">
                  <input type="date"
                         @change="loadVisits"
                         v-model="form.date"
                         class="form-control form-control-sm">
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('visit_id')}]">
                <label class="col-sm-3 col-form-label">Visit No.</label>
                <div class="col-sm-9">
                  <select class="form-select form-select-sm"
                          v-model="form.visit_id">
                    <option value="">Select Visit</option>
                    <option :value="visit.id"
                            v-for="visit in visits"
                            :key="'visit_'+visit.id">{{ visit.visit_no }}</option>
                  </select>
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('service_id')}]">
                <label class="col-sm-3 col-form-label">Services No.</label>
                <div class="col-sm-9">
                  <select class="form-select form-select-sm"
                          v-model="form.service_id">
                    <option value="">Select Service</option>
                    <option :value="service.id"
                            v-for="service in services"
                            :key="'service_'+service.id">{{ service.name_th }}</option>
                  </select>
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('doctor_id')}]">
                <label class="col-sm-3 col-form-label">Doctor</label>
                <div class="col-sm-9">
                  <select class="form-select form-select-sm"
                          @change="loadDoctorSlot"
                          v-model="form.doctor_id">
                    <option value="">Select Doctor</option>
                    <option :value="doctor.id"
                            v-for="doctor in doctors"
                            :key="'doctor_'+doctor.id">{{ doctor.full_name }}</option>
                  </select>
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('doctor_slot_id')}]">
                <label class="col-sm-3 col-form-label">Doctor Slot</label>
                <div class="col-sm-9">
                  <select class="form-select form-select-sm"
                          v-model="form.doctor_slot_id">
                    <option value="">Select Slot</option>
                    <option :value="slot.id"
                            v-for="slot in doctorSlots"
                            :key="'slot_'+slot.id">{{ slot.slot_id + ' (' + slot.time_format + ')' }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal">ยกเลิกการสร้าง</button>

          <button class="btn btn-success"
                  :disabled="loading"
                  @click="submit">บันทึก</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      patients: [],
      visits: [],
      services: [],
      doctors: [],
      doctorSlots: [],
      patient: null,
      form: {
        patient_id: '',
        service_id: '',
        date: '',
        visit_id: '',
        doctor_id: '',
        doctor_slot_id: '',
      }
    }
  },
  created() {
    // this.loadVisits();
    this.loadServices();
    this.loadDoctors();
    this.loadDoctorSlot();
  },
  mounted() {
    this.loadPatients();
    this.modal = new window.bootstrap.Modal(document.getElementById('add-new-case-modal'));
  },
  methods: {
    show() {
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    initSelect2() {
      let self = this;

      window.$('.customer-select2').select2({
        placeholder: 'โปรดระบุ',
        dropdownParent: window.$('#add-new-case-modal')
      });

      window.$('.customer-select2').on('change.select2', function () {
        self.form.patient_id = window.$(this).val();
        self.loadPatientDetails();
        self.loadVisits();
      });
    },
    loadVisits() {
      this.visits = [];

      if (this.form.date && this.form.patient_id) {
        this.axios.get('/visits?date=' + this.form.date + '&patient_id=' + this.form.patient_id)
          .then(res => {
            this.visits = res.data.data;
          })
      }
    },
    loadServices() {
      this.services = [];

      this.axios.get('/services')
        .then(res => {
          this.services = res.data.data;
        })
    },
    loadDoctors() {
      this.doctors = [];

      this.axios.get('/doctors')
        .then(res => {
          this.doctors = res.data.data;
        })
    },
    loadDoctorSlot() {
      this.doctorSlots = [];

      if (this.form.date && this.form.doctor_id) {
        this.axios.get('/doctor-slots?doctor_id=' + this.form.doctor_id + '&date=' + this.form.date)
          .then(res => {
            this.doctorSlots = res.data.data;
          })
      }
    },
    loadPatients() {
      this.patients = [];

      this.axios.get('/patients')
        .then(res => {
          this.patients = res.data.data;

          setTimeout(() => {
            this.initSelect2();
          }, 500);
        })
    },
    loadPatientDetails() {
      this.patient = null;

      if (this.form.patient_id) {
        this.axios.get('/patients/' + this.form.patient_id)
          .then(res => {
            this.patient = res.data.data;
          })
      }
    },
    submit() {
      this.allErrors = null;
      this.loading = true;

      this.axios.post('/visit-cases', this.form)
        .then(() => {
          this.modal.hide();
          this.$emit('added');
        })
        .catch((err) => {
          this.allErrors = err.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  }
}
</script>