<template>
  <div class="box">
    <div class="box-header small-box-header">
      <h4 class="box-title mb-0">เลือก Doctor Slot</h4>
    </div>
    <div class="box-body p-3">
      <div class="row">
        <div class="col-8">
          <div class="form-group margin-less-form-group row">
            <label class="col-sm-2 col-form-label">Doctor</label>
            <div class="col-sm-10">
              <select class="form-select form-select-sm"
                      @change="selectDoctor"
                      v-model="form.cases[0].doctor.id">
                <option value="">Select Doctor</option>
                <option :value="doctor.id"
                        v-for="doctor in doctors"
                        :key="'doctor_'+doctor.id">{{ doctor.full_name }}</option>
              </select>
            </div>
          </div>
          <div class="form-group margin-less-form-group row">
            <label class="col-sm-2 col-form-label">Slot</label>
            <div class="col-sm-10">
              <div class="input-group input-group-sm" @click="pickSlot">
                <input type="text"
                       class="form-control form-control-sm"
                       :value="slot ? slot.date_format : ''" disabled>
                <span class="input-group-text"><i class="ti-calendar"></i></span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="box">
            <div class="box-header small-box-header">
              <h4 class="box-title mb-0">บริการที่เลือก ทั้งหมด {{ services.filter(s => form.cases[0].services.map(s => s.id).includes(s.id)).length }} รายการ</h4>
            </div>
            <div class="box-body p-3">
              <template v-for="service in services.filter(s => form.cases[0].services.map(s => s.id).includes(s.id))"
                        :key="'service_'+service.id">
                <span style="color:#5156BE;">◆</span> {{ service.name_th }} <br>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <slot-pick-modal @selectSlot="selectSlot" ref="slotPickModal" />
</template>

<script>
import SlotPickModal from "@/views/iframe/request/add/Step3/SlotPickModal.vue";
import {mapGetters} from "vuex";
export default {
  props: ['formData', 'errors'],
  components: {SlotPickModal},
  data() {
    return {
      doctors: [],
      form: {},
    }
  },
  computed: {
    ...mapGetters({
      slot: 'iframeModule/getSlot',
      services: 'iframeModule/getServices'
    })
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    errors: {
      handler(newValue) {
        this.allErrors = newValue;
      },
      deep: true
    },
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    },
  },
  created() {
    this.loadDoctors();
  },
  methods: {
    selectSlot(slot) {
      this.form.cases[0].slot = slot;
      this.form.cases[0].date = slot.date_raw;
      this.form.date = slot.date_raw;
    },
    selectDoctor() {
      if (this.form.cases[0].doctor) {
        let doctor = this.doctors.find(d => d.id === this.form.cases[0].doctor.id);

        this.$store.commit('iframeModule/setDoctor', doctor);
      } else {
        this.$store.commit('iframeModule/setDoctor', null);
      }
    },
    pickSlot() {
      if (this.form.cases[0].doctor) {
        this.$refs.slotPickModal.show();
      }
    },
    loadDoctors() {
      this.doctors = [];

      this.axios.get('/doctors')
        .then(res => {
          this.doctors = res.data.data;
        })
    }
  }
}
</script>