<template>
  <div class="content-header">
    <div class="d-flex align-items-center">
      <div class="me-auto">
        <h3 class="page-title">{{ $t('case.case') }}</h3>
        <div class="d-inline-block align-items-center">
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i class="mdi mdi-home-outline"></i></a></li>
              <li class="breadcrumb-item active" aria-current="page">{{ $t('case.case') }}</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="box">
        <div class="box-header small-box-header">
          <div class="row">
            <div class="col-md-7">
              <div class="input-group">
                <input type="text"
                       @keyup="searchText($event.target.value)"
                       class="form-control"
                       placeholder="ค้นหาด้วยการระบุ Case No. หรือ HN หรือ ชื่อ นามสกุล">
                <span class="input-group-text"><i class="ti-search"></i></span>
              </div>
            </div>
            <div class="col-md-2">
              <select class="form-select"
                      @change="refreshData"
                      v-model="queryParams.doctor_id">>
                <option value="">{{ $t('case.dr_all_doctor') }}</option>
                <option :value="doctor.id"
                        v-for="doctor in doctors"
                        :key="'doctor_'+doctor.id">{{ doctor.full_name }}</option>
              </select>
            </div>
            <div class="col-md-2">
              <select class="form-select"
                      @change="refreshData"
                      v-model="queryParams.status">
                <option value="">{{ $t('case.status_all') }}</option>
                <option value="1">Request</option>
                <option value="2">Open</option>
                <option value="3">Ongoing</option>
                <option value="4">Finished</option>
                <option value="5">Cancel</option>
              </select>
            </div>
            <div class="col-md-1">
              <div class="d-grid">
                <button type="button"
                        @click="$refs.addNewCaseModal.show()"
                        class="waves-effect waves-light btn btn-primary btn-sm">+ {{ $t('case.add_new') }}</button>
              </div>
            </div>
          </div>
        </div>
        <div class="box-body p-3">
          <table-component :columns="columns"
                           :rows="cases"
                           :sortable="sortable"
                           @sorting="sorting"
                           :pagination="pagination"
                           @search="search"
                           :search="false"
                           @pageChanged="pageChanged">

            <template v-slot:booking_no="{ row }">
              <router-link :to="{name: 'case_notes', params: {id: row.id}}">
                {{ row.booking_no }}
              </router-link>
            </template>

            <template v-slot:case_no="{ row }">
              <a href="#"
                 @click.prevent="openInNewTab({name: 'case_notes', params: {id: row.id }})">
                {{ row.case_no }}
              </a>
            </template>

            <template v-slot:customer="{ row }">
              <img :src="(row.patient && row.patient.image) ? row.patient.image : '/images/avatar-girl.png'"
                   onerror="this.src='/images/avatar-girl.png'"
                   class="rounded h-30 w-30">
              {{ row.patient.full_name }}
            </template>

            <template v-slot:doctor_full_name="{ row }">
              <img :src="(row.doctor && row.doctor.image) ? row.doctor.image : '/images/avatar-girl.png'"
                   onerror="this.src='/images/avatar-girl.png'"
                   class="rounded h-30 w-30">
              {{ row.doctor ? row.doctor.full_name : '' }}
            </template>

            <template v-slot:staff="{ row }">
              <img :src="staff.user.image ? staff.user.image : '/images/avatar-girl.png'"
                   onerror="this.src='/images/avatar-girl.png'"
                   class="h-30 rounded me-2"
                   v-for="staff in row.staffs"
                   :key="'staff_'+staff.id">
            </template>

            <template v-slot:status="{ row }">
              <span class="badge badge-warning"
                    @click="showConfirm(row)"
                    v-if="row.status == 1">Request</span>
              <case-status :id="row.id"
                           v-else
                           :status="row.status" />

            </template>

            <template v-slot:action="{ row }">
              <template v-if="row.status !== 4">
                <a href="#"
                   class="text-dark"
                   @click.prevent="$refs.lockCaseModal.show(row.id)">
                  <i class="fas fa-clipboard-check"></i> เสร็จสิ้น
                </a>
                |
              </template>

              <router-link :to="{name: 'case_notes', params: {id: row.id}}"
                           class="text-dark">
                <i class="fas fa-file"></i> {{ $t('case.view') }}
              </router-link>
              <template v-if="row.status !== 5">
                |
                <a class="text-dark"
                   @click.prevent="cancelCase(row)"
                   href="#">
                  <i class="far fa-times-circle"></i> {{ $t('case.cancel') }}
                </a>
              </template>
            </template>
          </table-component>
        </div>
      </div>
    </div>
  </div>

  <add-new-case-modal @added="loadData" ref="addNewCaseModal" />
  <confirm-case-modal ref="confirmCaseModal" />
  <lock-case-modal @updated="loadData" ref="lockCaseModal" />
</template>

<script>
import TableComponent from "@/components/TableComponent";
import AddNewCaseModal from "./modals/AddNewCaseModal";
import CaseStatus from "@/views/case/CaseStatus.vue";
import ConfirmCaseModal from "./modals/ConfirmCaseModal.vue";
import LockCaseModal from "./modals/LockCaseModal.vue";
export default {
  components: {TableComponent, AddNewCaseModal, CaseStatus, ConfirmCaseModal,
    LockCaseModal},
  data() {
    return {
      cases: [],
      doctors: [],
      pagination: null,
      queryParams: {
        status: '',
        without_request: '1',
        doctor_id: '',
        page: 1,
        search: '',
        sort: 'created_at',
        sort_order: 'desc',
        per_page: 10
      },
      sortable: {
        order: 'created_at',
        sort: 'desc'
      },
      columns: [
        {
          label: this.$t('case.case_no'),
          field: "case_no",
          sortable: true,
          searchable: true,
          slot: true,
        },
        {
          label: this.$t('case.visit_no'),
          field: "visit.visit_no",
          sortable: true,
          searchable: true,
        },
        {
          label: this.$t('case.hn'),
          field: "patient.hn",
          sortable: false,
          searchable: true,
          display(row) {
            return row.patient ? row.patient.hn : '';
          }
        },
        {
          label: this.$t('case.name'),
          field: "patient.full_name",
          sortable: false,
          searchable: true,
        },
        {
          label: this.$t('case.doctor'),
          field: "doctor.full_name",
          sortable: true,
          searchable: true,
          slot: true,
        },
        {
          label: this.$t('case.staff'),
          field: "staff",
          sortable: false,
          searchable: false,
          slot: true,
        },
        {
          label: this.$t('case.date'),
          field: 'visit.date',
          sortable: true,
          searchable: false,
        },
        {
          label: this.$t('case.time'),
          field: 'doctorSlot.time_format',
          sortable: false,
          searchable: false,
        },
        {
          label:  this.$t('case.status'),
          field: 'status',
          sortable: true,
          searchable: false,
          slot: true
        },
        {
          label:  this.$t('case.action'),
          field: 'action',
          sortable: false,
          searchable: false,
          slot: true,
          class: ['text-end']
        }
      ],
    }
  },
  created() {
    this.loadData();
    this.loadDoctors();
  },
  methods: {
    loadDoctors() {
      this.doctors = [];

      this.axios.get('/doctors')
        .then(res => {
          this.doctors = res.data.data;
        })
    },
    loadData() {
      this.cases = [];

      this.axios.get('/visit-cases', {params: this.queryParams})
        .then((response) => {
          this.cases = response.data.data;
          this.pagination = response.data.meta;
        })
    },
    showConfirm(row) {
      this.$refs.confirmCaseModal.show(row);
    },
    cancelCase(row) {
      this.$swal({
        title: 'Are you sure want to cancel?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, cancel it!',
        confirmButtonColor: '#dd4b39',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.post('/visit-cases/change-status', {
              visit_case_id: row.id,
              status: 5
            })
            .then(() => {
              row.status = 5;
            })
        }
      });
    }
  }
}
</script>