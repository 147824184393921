<template>
  <div class="row">
    <div class="col-8">
      <div class="row bg-light pt-3 rounded">
        <div class="col-4">
          <div class="d-flex align-items-center">
            <div class="me-15">
              <img v-if="form.patient_type === '1'"
                   :src="patient.image ? patient.image : '/images/avatar-girl.png'"
                   onerror="this.src='/images/avatar-girl.png'"
                   class="avatar avatar-lg rounded10">

              <img v-else
                   :src="form.patient.image ? form.patient.image : '/images/avatar-girl.png'"
                   onerror="this.src='/images/avatar-girl.png'"
                   class="avatar avatar-lg rounded10">
            </div>
            <div class="d-flex flex-column flex-grow-1 fw-500">
              <span class="text-fade">Customer name</span>
              <a href="#" class="text-dark hover-primary mb-1 fs-16">
                {{ form.patient_type === '1' ? patient.full_name : (form.patient.first_name + ' ' + form.patient.last_name) }}
              </a>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="d-flex align-items-center">
            <div class="me-15">
              <img :src="form.doctor.image ? form.doctor.image : '/images/avatar-girl.png'"
                   onerror="this.src='/images/avatar-girl.png'"
                   class="avatar avatar-lg rounded10">
            </div>
            <div class="d-flex flex-column flex-grow-1 fw-500">
              <span class="text-fade">Doctor</span>
              <a href="#" class="text-dark hover-primary mb-1 fs-16">{{ form.doctor.full_name }}</a>
            </div>
          </div>
        </div>
        <div class="col-4">
          <table class="patient-details-table table table-borderless table-sm">
            <tr>
              <td>
                <span style="color: rgb(81, 86, 190);">◆</span> Requested Date
              </td>
              <td>: {{ caseRequest.created_at }}</td>
            </tr>
            <tr>
              <td>
                <span style="color: rgb(81, 86, 190);">◆</span> HN
              </td>
              <td>: {{ patient.hn }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-12 p-3 h-400">
          <div id="calendar"></div>
        </div>
      </div>
    </div>
    <div class="col-4">
      <h4 class="bg-info p-3 rounded"
          v-if="form.creatingNewVisit">Crate New Visit</h4>
      <select-visit @createVisit="createVisit"
                    :visits="previousVisits"
                    :visitId="form.visit_id"
                    :creatingNewVisit="form.creatingNewVisit"
                    @selectVisit="selectVisit"
                    v-if="previousVisits.length" />
      <no-visit v-else
                :creatingNewVisit="form.creatingNewVisit"
                @createVisit="createVisit" />
    </div>
  </div>
</template>

<script>
import NoVisit from "./NoVisit.vue";
import SelectVisit from "./SelectVisit.vue";
export default {
  props: ['formData', 'errors', 'currentPatient', 'caseRequest'],
  components: {NoVisit, SelectVisit},
  mounted() {
    this.initCalender();
  },
  created() {
    this.loadVisits(this.form.date);
  },
  data() {
    return {
      form: {},
      previousVisits: []
    }
  },
  computed: {
    patient() {
      return this.currentPatient;
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    errors: {
      handler(newValue) {
        this.allErrors = newValue;
      },
      deep: true
    },
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    },
  },
  methods: {
    createVisit() {
      this.form.creatingNewVisit = true;
      this.form.visit_id = null;
    },
    selectVisit(visit) {
      this.form.visit = visit;
      this.form.creatingNewVisit = false;
      this.form.visit_id = visit.id;
      // this.$emit('nextStep')
    },
    loadVisits(date) {
      this.previousVisits = [];

      if (this.form.patient_type == '1') {
        this.axios.get('/visits', {
            params: {
              patient_id: this.form.selected_patient_id,
              date: date
            }
          })
          .then(res => {
            this.previousVisits = res.data.data;
          })
      }
    },
    initCalender() {
      let self = this;

      let calendarEl = document.getElementById('calendar');
      let calendar = new window.FullCalendar.Calendar(calendarEl, {
        initialView: 'dayGridMonth',
        selectable: true,
        height: '100%',
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,dayGridWeek,timeGridDay,listWeek'
        },
        themeSystem: 'bootstrap5',
        dateClick: function(info) {
          self.form.date = info.dateStr;
          self.loadVisits(info.dateStr);
        }
      });
      calendar.render();
    }
  }
}
</script>