<template>
  <div class="row">
    <div class="col-md-6">
      <h4>Follow-up List</h4>
    </div>

    <div class="col-md-6 text-end">
      <button class="btn btn-sm btn-info"
              @click="$refs.addFollowUpModal.show()">
        <i class="fas fa-plus"></i> Create New
      </button>
    </div>
  </div>

  <hr>

  <div class="row">
    <div class="col-md-12">
      <div class="table-responsive">
        <table class="table table-small">
          <thead>
          <tr>
            <th>Task ID</th>
            <th>Title</th>
            <th>Date</th>
            <th>Status</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="task in tasks"
              :key="'task_'+task.id">
            <td>{{ task.task_id }}</td>
            <td>{{ task.title }}</td>
            <td>{{ task.date }}</td>
            <td>
              <span class="badge badge-info">Open</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <add-follow-up-modal @added="loadTasks"
                       ref="addFollowUpModal" />
</template>

<script>
import AddFollowUpModal from "@/views/case/modals/AddFollowUpModal.vue";
export default {
  components: {AddFollowUpModal},
  data() {
    return {
      tasks: []
    }
  },
  created() {
    this.loadTasks();
  },
  methods: {
    loadTasks() {
      this.tasks = [];

      this.axios.get('/visit-tasks?visit_case_id=' + this.$route.params.id)
        .then(res => {
          this.tasks = res.data.data;
        })
    }
  }
}
</script>