<template>
  <note-component :field="'note_nurse'"
                  :label="'Notes'" />

  <h6>File Attachment</h6>

  <div class="box">
    <div class="box-body p-3">
      <div class="row">
        <div class="col-6">
          {{ files.length }} Files
        </div>
        <div class="col-6 text-end">
          <button class="btn btn-sm btn-primary-light"
                  @click="$refs.fileInput.click()">
            <i class="fas fa-upload"></i> Upload
          </button>
        </div>
      </div>

      <hr>

      <table class="table">
        <thead>
        <tr>
          <th>No.</th>
          <th>File name</th>
          <th>File size</th>
          <th>Upload date</th>
          <th class="text-end">Action</th>
        </tr>
        </thead>

        <tbody>
        <tr v-for="(file, i) in files" :key="'file_'+file.id">
          <td>{{ i+1 }}</td>
          <td>{{ file.original_filename }}</td>
          <td>{{ parseInt(file.size) }} kb</td>
          <td>{{ file.created_at }}</td>
          <td class="text-end">
            <a :href="file.file"
               target="_blank"
               class="text-muted"><i class="fas fa-eye"></i> {{ $t('setting.view') }}
            </a> |

            <a href="#" class="text-muted"
               @click.prevent="deleteData(file)"><i class="fas fa-trash"></i> {{ $t('setting.delete') }}</a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <input @change="readFileUrl($event.target)"
         type="file"
         ref="fileInput" style="display: none">
</template>

<script>
import NoteComponent from "@/views/case/components/NoteComponent.vue";
export default {
  components: {NoteComponent},
  data() {
    return {
      files: []
    }
  },
  created() {
    this.loadFiles();
  },
  methods: {
    loadFiles() {
      this.files = [];

      this.axios.get('/visit-cases/' + this.$route.params.id + '/files')
        .then(res => {
          this.files = res.data.data;
        })
    },
    readFileUrl(input) {
      if (input.files && input.files[0]) {
        let formData = new FormData();

        formData.append('file', input.files[0]);
        formData.append('visit_case_id', this.$route.params.id);
        formData.append('original_filename',  input.files[0].name);

        this.axios.post('/visit-cases/file/upload', formData)
          .then(() => {
            this.loadFiles();
          });
      }
    },
    deleteData(row) {
      this.$swal({
        title: this.$t('patients.are_you_sure_want_to_delete'),
        text: this.$t('patients.you_wont_be_able_to_revert_this'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('patients.cancel'),
        confirmButtonText: this.$t('patients.yes_delete_it'),
        confirmButtonColor: '#dd4b39',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete('/visit-cases/files/'+row.id).then(() => {
            this.loadFiles();
          });
        }
      });
    },
  }
}
</script>