<template>
  <div class="content-header">
    <div class="d-flex align-items-center">
      <div class="me-auto">
        <h3 class="page-title">{{ $t('case.request') }}</h3>
        <div class="d-inline-block align-items-center">
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i class="mdi mdi-home-outline"></i></a></li>
              <li class="breadcrumb-item active" aria-current="page">{{ $t('case.request') }}</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="box">
        <div class="box-header small-box-header">
          <div class="row">
            <div class="col-md-12">
              <div class="input-group">
                <input type="text"
                       @keyup="searchText($event.target.value)"
                       class="form-control"
                       placeholder="ค้นหาด้วยการระบุ Case No. หรือ HN หรือ ชื่อ นามสกุล">
                <span class="input-group-text"><i class="ti-search"></i></span>
              </div>
            </div>
          </div>
        </div>
        <div class="box-body p-3">
          <table-component :columns="columns"
                           :rows="cases"
                           :sortable="sortable"
                           @sorting="sorting"
                           :pagination="pagination"
                           @search="search"
                           :search="false"
                           @pageChanged="pageChanged">

            <template v-slot:booking_no="{ row }">
              <router-link :to="{name: 'case_notes', params: {id: row.id}}">
                {{ row.booking_no }}
              </router-link>
            </template>

            <template v-slot:customer="{ row }">
              <img :src="row.patient.image ? row.patient.image : '/images/avatar-girl.png'"
                   onerror="this.src='/images/avatar-girl.png'"
                   class="rounded h-30 w-30">
              {{ row.patient.full_name }}
            </template>

            <template v-slot:case_no="{ row }">
              <a v-if="row.visitCase"
                 href="#"
                 @click.prevent="openInNewTab({name: 'case_notes', params: {id: row.visit_case_id }})">
                {{ row.visitCase ? row.visitCase.case_no : '' }}
              </a>
            </template>

            <template v-slot:task_id="{ row }">
              <a v-if="row.visitTask"
                 href="#"
                 @click.prevent="openInNewTab({name: 'tasks_view', params: {id: row.visit_task_id }})">
                {{ row.visitTask ? row.visitTask.task_id : '' }}
              </a>
            </template>

            <template v-slot:doctor_full_name="{ row }">
              <img :src="row.doctor.image ? row.doctor.image : '/images/avatar-girl.png'"
                   onerror="this.src='/images/avatar-girl.png'"
                   class="rounded h-30 w-30">
              {{ row.doctor.full_name }}
            </template>

            <template v-slot:staff="{ row }">
              <img :src="staff.user.image ? staff.user.image : '/images/avatar-girl.png'"
                   onerror="this.src='/images/avatar-girl.png'"
                   class="h-30 rounded me-2"
                   v-for="staff in row.staffs"
                   :key="'staff_'+staff.id">
            </template>

            <template v-slot:status="{ row }">
              <request-status :id="row.id"
                              :status="row.status" />

            </template>

            <template v-slot:action="{ row }">
              <div class="dropdown">
                <router-link :to="{name: 'case_requests_details', params: {id: row.id}}"
                             class="btn btn-sm btn-outline btn-dark me-1">
                  <i class="fas fa-info"></i>
                </router-link>
                <button class="btn btn-sm btn-outline btn-dark dropdown-toggle"
                        v-if="row.status === 1"
                        type="button"
                        data-bs-toggle="dropdown">Convert</button>
                <div class="dropdown-menu">
                  <router-link class="dropdown-item"
                               :to="{name: 'case_requests_details', params: {id: row.id}}">Convert to Case</router-link>
                  <router-link class="dropdown-item"
                               :to="{name: 'case_requests_details', params: {id: row.id}, query: {task: '1'}}">Convert to Task</router-link>
                </div>
              </div>
            </template>
          </table-component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TableComponent from "@/components/TableComponent";
import RequestStatus from "@/views/case/RequestStatus.vue";
export default {
  components: {RequestStatus, TableComponent},
  data() {
    return {
      cases: [],
      doctors: [],
      pagination: null,
      queryParams: {
        page: 1,
        search: '',
        sort: 'created_at',
        sort_order: 'desc',
        per_page: 10
      },
      sortable: {
        order: 'created_at',
        sort: 'desc'
      },
      columns: [
        {
          label: 'Request No.',
          field: "request_id",
          sortable: true,
          searchable: true,
        },
        {
          label: 'Created Date',
          field: "created_at",
          sortable: true,
          searchable: true,
        },
        {
          label: 'Customer Name',
          field: "koalendar_customer_name",
          sortable: true,
          searchable: true,
        },
        {
          label:  this.$t('case.status'),
          field: 'status',
          sortable: true,
          searchable: false,
          slot: true
        },
        {
          label: 'Case ID',
          field: "case_no",
          sortable: false,
          searchable: false,
          slot: true,
        },
        {
          label: 'Task ID',
          field: "task_id",
          sortable: false,
          searchable: false,
          slot: true,
        },
        {
          label:  this.$t('case.action'),
          field: 'action',
          sortable: false,
          searchable: false,
          slot: true,
          class: ['text-end']
        }
      ],
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.cases = [];

      this.axios.get('/case-requests', {params: this.queryParams})
        .then((response) => {
          this.cases = response.data.data;
          this.pagination = response.data.meta;
        })
    },
  }
}
</script>