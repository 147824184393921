<template>
  <div class="row">
    <div class="col-md-6">
      <h4>ประวัติรายการสั่งยาและผลิตภัณฑ์</h4>
    </div>

    <div class="col-md-6 text-end">
      <button class="btn btn-sm btn-info"
              @click="$refs.prescriptionModal.show()">
        <i class="fas fa-plus"></i> สั่งยา/ผลิตภัณฑ์
      </button>
    </div>
  </div>

  <hr>

  <div class="row">
    <div class="col-12">
      <div class="table-responsive">
        <table class="table table-small">
          <thead>
          <tr>
            <th>Case No.</th>
            <th>วันที่นัดหมาย</th>
            <th>ชื่อรายการ</th>
            <th class="text-end">ราคา</th>
            <th class="text-end">จำนวน</th>
            <th>หน่วยนับ</th>
            <th class="text-end">ราคารวม</th>
            <th class="text-end">จัดการ</th>
          </tr>
          </thead>

          <tbody>
          <template v-for="prescription in prescriptions"
                    :key="'prescription_'+prescription.id">
            <tr>
              <td :rowspan="prescription.products.length">{{ prescription.visit_case.case_no }}</td>
              <td :rowspan="prescription.products.length">{{ prescription.visit_case.date }}</td>
              <td>{{ findProductAttribute(prescription.products[0].product_id, 'product_name') }}</td>
              <td class="text-end">{{ findProductAttribute(prescription.products[0].product_id, 'sell_price')  }}</td>
              <td class="text-end">{{ prescription.products[0].quantity }}</td>
              <td>{{ findProductAttribute(prescription.products[0].product_id, 'unit') }}</td>
              <td class="text-end">{{ findProductAttribute(prescription.products[0].product_id, 'sell_price') * prescription.products[0].quantity }}</td>
              <td :rowspan="prescription.products.length"
                  class="text-end">
                <a href="#" @click.prevent="$refs.prescriptionModal.show(prescription)" class="text-info">
                  <i class="fas fa-edit"></i> แก้ไข
                </a> |
                <a href="#"
                   class="text-danger"
                   @click.prevent="deleteData(prescription)">
                  <i class="fas fa-trash"></i> ลบ
                </a>
              </td>
            </tr>

            <template v-for="(product, i) in prescription.products"
                      :key="'product_'+product.id">
              <tr v-if="i !== 0">
                <td>{{ findProductAttribute(product.product_id, 'product_name')}}</td>
                <td class="text-end">{{ findProductAttribute(product.product_id, 'sell_price') }}</td>
                <td class="text-end">{{ product.quantity }}</td>
                <td>{{ findProductAttribute(product.product_id, 'unit') }}</td>
                <td class="text-end">{{ findProductAttribute(product.product_id, 'sell_price') * product.quantity }}</td>
              </tr>
            </template>
          </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <add-prescription-modal @added="loadPrescriptions"
                          ref="prescriptionModal" />
</template>

<script>
import AddPrescriptionModal from "@/views/case/modals/AddPrescriptionModal/AddPrescriptionModal.vue";

export default {
  components: {AddPrescriptionModal},
  data() {
    return {
      prescriptions: []
    }
  },
  async created() {
    await this.loadProducts();
    this.loadPrescriptions();
  },
  methods: {
    findProductAttribute(id, attribute) {
      let product = this.products.find(p => p.product_id === id);

      if (product) {
        return product[attribute];
      }

      return '';
    },
    async loadProducts() {
      this.products = [];

      await this.axios.get('/inventory/products')
        .then(res => {
          this.products = res.data;
        })
    },
    loadPrescriptions() {
      this.prescriptions = [];

      this.axios.get('/prescriptions?visit_case_id=' + this.$route.params.id)
        .then(res => {
          this.prescriptions = res.data.data;
        })
    },
    deleteData(row) {
      this.$swal({
        title: this.$t('patients.are_you_sure_want_to_delete'),
        text: this.$t('patients.you_wont_be_able_to_revert_this'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        confirmButtonColor: '#dd4b39',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete('/prescriptions/'+row.id).then(() => {
            this.loadPrescriptions();
            this.$swal.fire(
              'Deleted',
              'Prescription has been deleted!',
              'success'
            )
          });
        }
      });
    },
  }
}
</script>